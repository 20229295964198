import React, { Component } from 'react';
import TopNav from '../components/navs/TopNav';
import Orders from '../components/Orders';
import Profile from '../components/Profile';
import { connect } from 'react-redux';
import { switch_content } from '../store/actions/SwitchContent';
import RatingS from '../components/Rating';
import ListSortDemo from '../components/Lives';
import Procedures from '../components/Procedures';
import Ticket from '../components/Ticket';
import pix1 from '../img/1.png';
import pix2 from '../img/2.png';
import pix3 from '../img/3.png';
import pix4 from '../img/4.png';
import pix5 from '../img/5.png';
import pix6 from '../img/6.png';
import loan from '../img/loan.png';
import pro from '../img/user.png';
import EditProfile from '../components/EditProfile';
import Axios from 'axios';
import { Home } from '../global/Home';
import Verify from '../components/Verify';
import { login_again } from '../store/actions/Rlogin';
import { Redirect } from 'react-router-dom';
import UpgradeAccount from '../components/UpgradeAccount';
import ChatList from '../components/ChatList';
import FullChat from '../components/FullChat';



class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current:"",
            rate:"",
            scroller:false,
            laoding:false,
            details:"",
            div:0,
            modal:""
        }
    }

    ChangeModal=(name)=>{
        this.setState({modal:name});
      }

    ChangeHanhle=(name)=>{
        // console.log(name)
        this.props.dispatch(switch_content(name))
       // this.setState({current:name})
    }

    Relogin=()=>{
        this.props.dispatch(login_again(true));
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({details:res.data,laoding:false,div:res.data.div});
           
           })
        .catch(err =>this.Relogin());
        }
    }

    Modal=(name)=>{
        this.setState({rate:name});
    }

    trackScrolling = () => {
        if (window.pageYOffset >= 100) {
          this.setState({sroller:true});
        }else{
          this.setState({sroller:false});
        }
      }
    
    componentWillUnmount() {
        clearInterval(this.interval);
        document.removeEventListener('scroll', this.trackScrolling);
    }
    componentDidMount(){
        this.LoadEditData()
        document.addEventListener('scroll', this.trackScrolling);
          //this.interval = setInterval(() => this.changeStyle(), 6000);
          
        }

        UNSAFE_componentWillReceiveProps(nextProps){
            if (this.props.data.numberV !== nextProps.data.numberV) {
              this.LoadEditData();
            }
            if (this.props.data.login !== nextProps.data.login) {
                this.props.history.push('/login');
              }
            
              if (this.props.data.toggle !== nextProps.data.toggle) {
                this.ChangeHanhle('Ticket')
              }
        }

    Switcher=()=>{
        let data = this.props.data.switch;
        let width = false;
        if (window.innerWidth <= 700) {
            width = true;
        }else{
            width = false
        }
        if (data === 'Orders') {
            return <Orders close={()=>this.ChangeHanhle('')} />
        }else if (data === 'Testimonial') {
            return <h1>Coming Soon</h1>;
        }else if (data === 'Edit_Profile') {
            return <EditProfile />;
        }else if (data === 'Profile') {
            return <Profile />;
        }else if (data === 'Lives') {
            return <ListSortDemo />
        }else if (data === 'Procedures') {
            return <Procedures />
        }else if (data === 'Chat') {
            return <Ticket />
        }else if (data === 'Ticket') {
            return <FullChat />
        }else{ 
          return  <div className="row mt-5 justify-content-center">
                    <div className="col-lg-12">
                        <div className="title-heading text-center">
                            <div>
                            <h1 className="heading title-dark text-white mb-3">Welcome {this.state.laoding? 'loading...': this.state.details.name}
                            {this.state.div === 1 ? <span className="badge badge-pill badge-success st--notification-number-anim2 st-cusure"> Account Verified </span>  
                            : '' }
                            {this.state.div === 0 ? 
                            <span onClick={()=>this.Modal('WHOCODED_VERIFY')} className="badge badge-pill badge-danger st--notification-number-anim st-cusure"> Verify Account </span>
                        :''
                        }
                        {this.state.div === 2 ?
                            <span onClick={()=>this.ChangeModal('WHOCODED')} className="badge badge-pill bgbg st--notification-number-anim3 st-cusure" style={{borderRadius:'6px'}}> <span style={{color:'green'}}>Phone Verified</span>  <br></br> <span style={{color:'red'}}>Upgrade Account</span> </span>
                        :''
                        }
                            
                             </h1>
                            
                            </div>
                            
                            <div className="row mt-4 pt-2">
                             

                            
                            
                                


                            <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=>this.state.div === 1 || this.state.div === 2 ?this.ChangeHanhle('Profile') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pro} className="avatar avatar-md-sm-st" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Your Profile</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=> this.state.div === 1 || this.state.div === 2 ? this.ChangeHanhle('Procedures') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                        <div className="card-body" style={{padding:'1.5rem 0rem'}}>
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix1} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Payment procedure</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {width ?
                             <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=>this.state.div === 1 || this.state.div === 2 ? this.props.history.push('/dashboard/buy') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded text-center bg-white pointer st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix4} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Buy</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :''}

                            {width ?
                            <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                            <div onClick={()=>this.state.div === 1 || this.state.div === 2 ? this.ChangeHanhle('Ticket') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                <div className="card-body">
                                    <div className="icon rounded-circle shadow-lg d-inline-block">
                                        <img src={loan} className="avatar avatar-md-sm" alt="" />
                                    </div>
                                    <div className="content mt-3">
                                        <h6 className="mb-0"><a href="#" className="title text-dark">Sell</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :''}

                                <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=>this.state.div === 1 || this.state.div === 2 ? this.ChangeHanhle('Orders') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded text-center bg-white pointer st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix3} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">My Orders</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                               
                                <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=>this.ChangeHanhle('Lives')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix6} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Our Rates</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=> this.state.div === 1 || this.state.div === 2 ? this.ChangeHanhle('Ticket'): this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix2} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Supports</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                                    <div onClick={()=>this.state.div === 1 || this.state.div === 2 ? this.Modal('WHOCODED') : this.Modal('WHOCODED_VERIFY')} className="card explore-feature border-0 rounded pointer text-center bg-white st-hover">
                                        <div className="card-body">
                                            <div className="icon rounded-circle shadow-lg d-inline-block">
                                                <img src={pix5} className="avatar avatar-md-sm" alt="" />
                                            </div>
                                            <div className="content mt-3">
                                                <h6 className="mb-0"><a href="#" className="title text-dark">Rate Us</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
        }
    }
    render() {
        if (!localStorage.getItem('userToken')) {
            return <Redirect to="/login" />
        }
        return (
            <div className={`${this.props.data.switch === 'Ticket'? 'st-dark2 no-scroller': ' st-dark2 scroller' }`} style={{height:'100vh'}}>
                <TopNav sticky={this.state.sroller? 'nav-sticky ':`${this.props.data.switch === 'Ticket'?' bg-dark22 ':''}`} />
                <section className={`${this.props.data.switch === 'Ticket'? 'sto ': ' bg-half-170 bg-dark2 d-table w-100 bgg2 ' }`}  >
                <div className={`${this.props.data.switch === 'Ticket' ? ' ':'container'}`}>
                    {this.state.rate === 'WHOCODED'?
                   <RatingS 
                    close={()=>this.Modal('')}
                   />  
                :''}

                 {this.state.rate === 'WHOCODED_VERIFY'?
                   <Verify 
                    close={()=>this.Modal('')}
                   />  
                :''}
                {this.state.modal === 'WHOCODED' ?
              <UpgradeAccount 
              close={()=>this.ChangeModal('')}
               />
            :''}
                {this.Switcher()}
            </div>
                </section>
            </div>
        );
    }
}


const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }
  
export default connect(mapStoreToProps) (Dashboard);