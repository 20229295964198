import React, { Component } from 'react';
import TopNav from '../components/navs/TopNav';
import ParticleEffectButton from 'react-particle-effect-button'
import Particles from 'react-particles-js';
import LogoBitcoin from 'react-ionicons/lib/LogoBitcoin'
import Carousels from '../components/Carosel';
import wallet from '../img/purse.png'
import user from '../img/user.png'
import success from '../img/success.png'
import Footer from '../components/Footer';
import Testimonial from '../components/Testimonial';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import BTCLIVE from '../components/BTCLIVE';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden:false,
            you:true,
            bt:false,
            bt1:false,
            nam:true,
            amount:"",
            type:"0",
            totalBool:true,
            acnumber:"",
            acname:"",
            bankname:"",
            scroller:false,
            data:[],
            total:0

        }
    }

    Switch=(bool)=>{
        this.setState({you:bool});
        if (bool) {
            this.LoadData();
            this.setState({bt:!this.state.bt})
        }else{
            this.setState({bt1:!this.state.bt1});
            this.LoadData2();
        }
    }

    TotalSwitch=(bool)=>{
        this.setState({totalBool:bool});
    }

    LoadData=()=>{
        this.setState({loading:true});
            Axios.get(`${Home}rates2`,{
            })
           .then(res => {
            //  console.log(res);
           this.setState({loading:false,data:res.data});
           })
        .catch(err =>console.log(err));
    }

    LoadData2=()=>{
        this.setState({loading:true});
            Axios.get(`${Home}rates`,{
            })
           .then(res => {
            //  console.log(res);
           this.setState({loading:false,data:res.data});
           })
        .catch(err =>console.log(err));
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        }
        let charge = 0;
        let charge2 = 0;
        let charge3 = 0;
        if (event.target.name === 'amount' || event.target.name === 'type') {
                let buy = this.state.data[this.state.type].sell;
                let total = event.target.value * buy;
                this.setState({total:total});
                if (event.target.value.length > 0) {
                    this.setState({totalBool:false})
                }else{
                    this.setState({totalBool:true})
                }
            if (!this.state.you) {
                if (event.target.value <= 20) {
                    charge = 0;
                    charge2 = 0;
                    charge3 = 100;
                }else if (event.target.value > 20 && event.target.value <= 500) {
                    charge = 0;
                    charge2 = 0;
                    charge3 = 0;
                }else if (event.target.value > 500) {
                    charge = 3;
                    charge2 = 0;
                    charge3 = 0;
                }
                let buy = parseInt(this.state.data[this.state.type].buy) + charge;
                let total2 = event.target.value * buy;
                let total = total2 +charge2 - charge3;
                this.setState({total:total});
                if (event.target.value.length > 0) {
                    this.setState({totalBool:false})
                }else{
                    this.setState({totalBool:true})
                }
            }
            
        }
      }

      trackScrolling = () => {
        if (window.pageYOffset >= 200) {
          this.setState({sroller:true});
        }else{
          this.setState({sroller:false});
        }
      }

      componentDidMount(){
          this.LoadData()
        document.addEventListener('scroll', this.trackScrolling);
          //this.interval = setInterval(() => this.changeStyle(), 6000);
          
        }

        handleSubmit=(event,e)=>{
            event.preventDefault();
            //console.log(e)
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          document.removeEventListener('scroll', this.trackScrolling);
        }

    
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        return (
            <div onClick={()=>this.setState({hidden:!this.state.hidden})}>
                <TopNav sticky={this.state.sroller? 'nav-sticky':''} />
                <section className="bg-half-260 home-pad bg-primary d-table w-100 bgg" style={{height:'100vh'}}>
                    <div className="bg-overlay"></div>
                    <div id="home">
                    <Particles 
                      params={{
                        "particles": {
                            "number": {
                                "value": 50
                            },
                            "size": {
                                "value": 3
                            }
                        },
                        "interactivity": {
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                }
                            }
                        }
                    }}
                    />

                    </div>

                    <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title-heading text-center mt-4">
                            <h1 className="display-4 title-dark font-weight-bold text-white mb-3">Quick, Easy & Fast Payments</h1>
                            <p className="para-desc mx-auto text-white-50"> Start to Finish under 5 minutes, Safe delivery is a priority. We communicate to you every step on the way. &nbsp; &nbsp; &nbsp;</p>
                            {localStorage.getItem('userToken') ? 
                            <div className="mt-4 pt-2">
                            <Link className="btn btn-primary" to="/dashboard">Go To Your Dashboard</Link>
                         </div>
                        :
                        <div className="mt-4 pt-2">
                               <Link className="btn btn-primary" to="/register">Create an account</Link>
                               <Link className="btn btn-secondary" to="/login" style={{marginLeft:'3px'}}>Login Now</Link>
                            </div>
                        }
                            
                        </div>
                    </div>
                </div>
            </div>
                    
                    
   

                    
                </section>

                <section className="section">
                    <div className="container">


                    <div className="row">
                       <div className="col-md-1"></div>
                       <div className="col-md-10">
                       <button onClick={()=>this.Switch(true)} className={`btn btn-primary ${this.state.nam ? 'active' :''}`}><i className="mdi mdi-email"></i>
                       <ParticleEffectButton
                       onComplete={()=>this.setState({nam:true})}
                            duration={1000}
                                color='#35357D'
                                hidden={this.state.bt}
                            >
                           <i></i>
                       </ParticleEffectButton>
                        Buy from us
                       
                       </button>
                       <button onClick={()=>this.Switch(false)} className={`btn btn-primary ${!this.state.nam ? 'active' :''}`} style={{marginLeft:'5px'}}><i className="mdi mdi-email"></i> Sell to us
                       <ParticleEffectButton
                       onComplete={()=>this.setState({nam:false})}
                            duration={1000}
                                color='#35357D'
                                hidden={this.state.bt1}
                            >
                           <i></i>
                       </ParticleEffectButton>
                       </button>
                       
                       
                       {this.state.you ?
                       
                       <form onSubmit={this.handleSubmit} className="rounded p-4 mt-0 bg-white shadow-lg">
                        
                        <div className="row text-left">
                                   <div className="col-md-3">
                                       <div className="form-group mb-0">
                                           <input onChange={this.handleChange} 
                                           value={this.state.amount}
                                           name="amount" 
                                           type="text" 
                                           className="form-control" 
                                           required="" 
                                           placeholder="How much do you need in dollar"/>
                                       </div>
                                   </div>
                                   
                                   <div className="col-md-9">
                                       <div className="row align-items-center">
                                           <div className="col-md-3 mt-4 mt-sm-0">
                                               <div className="form-group mb-0">
                                                   <select name="type" value={this.state.type} onChange={this.handleChange} className="form-control custom-select">
                                                       <option value="">Select Currency</option>
                                                       {this.state.data.length > 0 ?
                                                      this.state.data.map((da,i)=>
                                                      <option key={da.id} value={i}>{da.title}</option>
                                                        ) 
                                                    :''}
                                                       
                                                   </select>
                                               </div>
                                           </div>
                                           
                                           <div className="col-md-5 mt-4 mt-sm-0">
                                               <div className="form-group mb-0">
                                               <input 
                                               name="address"
                                               value={this.state.address}
                                               type="text" 
                                               onChange={this.handleChange}
                                               className="form-control" required
                                                placeholder="Address"/>
                                               </div>
                                           </div>
   

                                           <div className="col-md-4 mt-4 mt-sm-0">
                                               {this.state.loading ?
                                               <Spinner />
                                            :
                                            this.state.amount < 19 ?
                                            <button type="button" id="search" name="search" className="searchbtn btn btn-dark btn-block p disabled"> Buy Now (min $20) </button>
                                            :
                                            <button onClick={()=>this.props.history.push('/login')} type="button" id="search" name="search" className="searchbtn btn btn-primary btn-block p"> Buy Now </button>
                                            }
                                               
                                           </div>
                                       </div>
                                   </div> 
                                   
                               </div>
                               <center>
                                   <div className={this.state.totalBool?'st-abss':'d-block'}>
                                   <ParticleEffectButton
                                        duration={1000}
                                            color='#35357D'
                                            hidden={this.state.totalBool}
                                        >
                                      <div className="alert alert-primary alert-pills mt-5 shodow-lg" role="alert">
                                        <span className="badge badge-pill badge-danger"> Total Amount </span>
                                            <span className="alert-content"> ₦{this.state.total}.00 </span>
                                            </div>
                                </ParticleEffectButton>
                                   </div>
                               
                               
                               </center>
                           </form>
                    
                    :
                    
                    <form onSubmit={this.handleSubmit} className="rounded p-4 mt-0 bg-white shadow-lg">
                        
                        <div className="row text-left">
                                   <div className="col-md-4">
                                       <div className="form-group mb-3">
                                           <input onChange={this.handleChange} 
                                           value={this.state.acnumber}
                                           required
                                           name="acnumber" 
                                           type="number" 
                                           className="form-control" 
                                           placeholder="Account Number"/>
                                       </div>
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group mb-3">
                                           <input onChange={this.handleChange} 
                                           required
                                           value={this.state.bankname}
                                           name="bankname" 
                                           type="text" 
                                           className="form-control" 
                                           required
                                           placeholder="Bank Name"/>
                                       </div>
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group mb-3">
                                           <input onChange={this.handleChange} 
                                           value={this.state.acname}
                                           name="acname"
                                           type="text"
                                           className="form-control" 
                                           required
                                           placeholder="Account Name"/>
                                       </div>
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group mb-0">
                                                   <select required
                                                   name="type" 
                                                   value={this.state.type} onChange={this.handleChange} 
                                                   className="form-control custom-select">
                                                    <option value="">Select currency</option>
                                                   {this.state.data.length > 0 ?
                                                      this.state.data.map((da,i)=>
                                                      <option key={da.id} value={i}>{da.title}</option>
                                                        ) 
                                                    :''}
                                                   </select>
                                               </div>
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group mb-0">
                                           <input onChange={this.handleChange} 
                                           value={this.state.amount}
                                           name="amount" 
                                           type="text" 
                                           className="form-control" 
                                           required
                                           placeholder="How much do you need in dollars"/>
                                       </div>
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group mb-0">
                                       <button onClick={()=>this.props.history.push('/login')} type="button" id="search" name="search" className="searchbtn btn btn-primary btn-block p" value="Buy Now">Sell Now</button>
                                       </div>
                                   </div>
                                   
                                   
                                   
                               </div>
                               <center>
                                   <div className={this.state.totalBool?'st-abss':'d-block'}>
                                   <ParticleEffectButton
                                        duration={1000}
                                            color='#35357D'
                                            hidden={this.state.totalBool}
                                        >
                                      <div className="alert alert-primary alert-pills mt-5 shodow-lg" role="alert">
                                        <span className="badge badge-pill badge-danger"> Total Amount </span>
                                            <span className="alert-content"> ₦{this.state.total}.00 </span>
                                            </div>
                                </ParticleEffectButton>
                                   </div>
                               
                               
                               </center>
                           </form>
                    
                    }
                       
                        <BTCLIVE />
                       </div>
                       <div className="col-md-1"></div>
                   </div>



                    </div>
                </section>


                <section className="section bg-light">
                    <div className="container">
                    <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">How To Buy Or Sell Bitcoins In Nigeria</h4>
                            <p class="text-muted para-desc mx-auto mb-0">Start to Finish under 5 minutes, Safe delivery is a priority.
We communicate to you every step on the way.</p>
                        </div>
                    </div>
                </div>
                    </div>

                    <div class="container">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="features mt-5">
                            <div class="image position-relative d-inline-block">
                                <img src={user} class="avatar avatar-small" alt=""/>
                            </div>

                            <div class="content mt-4">
                                <h4 class="title-2">Register And Verify Your ID</h4>
                                <p class="text-muted mb-0">Sign up and go through a very simple and interactive identity verification process and start trading immidiately!.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 col-12 mt-5">
                        <div class="features">
                            <div class="image position-relative d-inline-block">
                                <img src={wallet} class="avatar avatar-small" alt=""/>
                            </div>

                            <div class="content mt-4">
                                <h4 class="title-2">Create Order</h4>
                                <p class="text-muted mb-0">Login to your Coinwells account, click on create new order. Select Buy or Sell order and fill the amount..</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 col-12 mt-5">
                        <div class="features">
                            <div class="image position-relative d-inline-block">
                                <img src={success} class="avatar avatar-small" alt=""/>
                            </div>

                            <div class="content mt-4">
                                <h4 class="title-2">Get Funded!</h4>
                                <p class="text-muted mb-0">Processing time is instant and automated 24/7. Once our system confirms your transaction, it will be marked as “completed” and money sent immediately. .</p>
                            </div>
                        </div>
                    </div>

                   </div>
              </div>
                </section>

                <section className="section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Carousels />
                            </div>
                            <div className="col-md-6">
                            <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4 text-center">Testimnonials</h4>
                            <p class="text-muted para-desc mx-auto mb-0 text-center">
                                What your clients says
                            </p>
                        </div>
                                <Testimonial />
                            </div>
                           
                        </div>
                    </div>
                </section>

                <section className="section bg-light">
                    <div className="container">
                    <div className="row mt-5 pt-lg-3 justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                            <h4 className="title mb-4">Download Our App Now !</h4>
                            <p className="text-muted para-desc mx-auto">Start working with <span className="text-primary font-weight-bold">Coinwells</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                            <div className="mt-4">
                                <form>
                                    <div className="row justify-content-center">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="input-group mb-3">
                                                    <input name="number" id="number" type="number" className="form-control" placeholder="Your Cell Number. :" required="" aria-describedby="cellnumber"/>
                                                    <div className="input-group-append">
                                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.coinwellsapp" className="btn btn-primary submitBnt" type="submit" id="cellnumber">Send App Link</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <p className="text-muted">Or</p>
                                
                                <a href="#" className="btn btn-primary mt-2 mr-2"><i className="fa fa-apple"></i> App Store</a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.coinwellsapp" className="btn btn-outline-primary mt-2"><i className="fa fa-android"></i> Play Store</a>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </section>


                

                <Footer />
            </div>
        );
    }
}

export default HomePage;