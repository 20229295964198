  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import success from '../img/success.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IosMan from 'react-ionicons/lib/IosMan'
import validator from 'validator';
import Toaster from '../global/Toaster';
import ImageUploading from "react-images-uploading";
import MaterialIcon from 'material-icons-react';
import img from '../img/default.png'
const maxNumber = 1;
class UpgradeAccount extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false,
            numberError:"",
            number:"",
            reset:"",
            code:"",
            password:"",
            password2:"",
            pasError:"",
            picture2:[],
            upload:0,
            mode:""
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }
    onChangeImage = imageList => {
        this.setState({picture2:imageList})
      }
    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }


      handleSubmit3=(event)=>{
        event.preventDefault();
        let token = "";
        if (localStorage.getItem('userToken')) {
            if (this.state.picture2.length < 1) {
                alert('Select a picture');
                return false;
            }
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true});
            axios.post(`${Home}auth/uploadId`,{
                token: token,
               mode:this.state.mode,
               picture:this.state.picture2
               
            },{
                onUploadProgress: ProgressEvent =>{
                    let MyProgress = Math.round(ProgressEvent.loaded/ProgressEvent.total *100);
            
                   // console.log('Progress: '+ Math.round(ProgressEvent.loaded/ProgressEvent.total *100)+" %");
                    if(MyProgress < 99){
                        this.setState({upload:MyProgress})
                    }else{
                        this.setState({upload:0})
                    }
                    
                  }
            })
           .then(res => {
            //  console.log(res);
           if (res.data.success) {
            this.setState({loading:false,done:'WHOCODED_DONE'});
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
               this.setState({loading:false})
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        }
        
    }




    componentDidMount(){
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            <>
            <Toaster />
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404f2',overflow:'scroll',zIndex:'111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> <IosMan  beat={true} fontSize="31px" color="#000" /> Upgrade Your Account</h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                {this.state.reset === "" ?
                
                <form onSubmit={this.handleSubmit3}>
                    <div className="modal-body">
                        <div>
                            {this.state.upload > 0 ?
                            
                            <div className="progress-box">
                            <div className="progress">
                                <div className="progress-bar position-relative bg-primary" style={{width:`${this.state.upload}%`}}>
                                </div>
                            </div>
                            </div>
                        :''}
                        
                        </div>
                    {this.state.done === 'WHOCODED_DONE'?
                            <div className="st-display-flex">
                                <center>
                                <LazyLoadImage
                                  alt={"whocoded"}
                                  effect="blur"
                                  src={success}
                                  style={{width:'40%'}}
                                  />
                                    <div className="text-center">
                                        Identity submitted successfully.. We will review and upgrade your account pending verification <br></br>
                                        <button onClick={this.props.close} className="btn btn-sm btn-danger shadow st-sm-btn mb-2" style={{width:'fit-content'}}>Return</button>
                                    </div>
                                </center>
                            </div>
                            :
                      <div>
                        <div className="form-group mt-2">
                             <div className="form-group mt-2">
                                 <label>Choose a prefered mode</label>
                                 <select value={this.state.mode} onChange={this.handleChange} className="form-control" name="mode" required>
                                      <option value="">Select identity</option>
                                      <option value="Voter Card">Voter Card</option>
                                      <option value="Driver License">Driver License</option>
                                      <option value="National ID Card">National ID Card</option>
                                      <option value="International Passport">International Passport</option>
                                    </select>
                             
                            </div>
                            

                            <center>
                                            <div className="form-group mb-2" id="st-up">
                                            <ImageUploading onChange={this.onChangeImage} maxNumber={maxNumber} multiple>
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                            // write your building UI
                                            <div>
                                            {this.state.picture2.length < 1 ?
                                            <>
                                            <button type="button" className="btn btn-primary shadow-sm d-flex" onClick={onImageUpload}>
                                              <MaterialIcon color="#ffffff" icon="add" />
                                            </button>
                                                <div className="avatar avatar-xxl">
                                                    <img className="rounded-circl shadow-lg sty" src={img} />
                                                    </div>
                                            </>
                                            :''
                                            }
                                                
                                            <div className="row">
                                                {imageList.map(image => (
                                                <div className="col-md-12 p-2" key={image.key}>
                                                <button type="button" className="st-btn btn-danger shadow-sm mt-2" onClick={image.onUpdate}>Change</button>
                                                    <div className="avatar avatar-xxl">
                                                    <img className="rounded-circl shadow-lg sty" src={image.dataURL} />
                                                    </div>
                                                    <div>
                                                
                                                    </div>
                                                    
                                                
                                                </div>
                                                
                                                ))}
                                            </div>
                                            </div>
                                            )}
                                    </ImageUploading>
                                    </div>
                                            </center>
                        </div>

                      </div>
                  }
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Upload Identity</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>

                :''}
                
                    </div>
                </div>
                </div>
           </>
        );
    }
}

export default UpgradeAccount;