import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/user.png'
import ImageUploading from "react-images-uploading";
import MaterialIcon from 'material-icons-react';
import { toast } from 'react-toastify';
import Toaster from '../global/Toaster';

const maxNumber = 1;
class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state={
            style:"st-hover2",
            details:"",
            laoding:false,
            firstName:"",
            lastName:"",
            address:"",
            picture:"",
            pictures:[],
            picture2:[],
        }
    }
    
    changeStyle=(name)=>{
        this.setState({style:name})
    }
    onChangeImage = imageList => {
        this.setState({picture2:imageList})
        // console.log(this.state.picture2);
      }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        }
      }

    ChangeHandle=(name)=>{
        this.props.dispatch(switch_content(name))
       // this.setState({current:name})
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            console.log(res)
           this.setState({details:res.data,laoding:false});
           if (typeof(res.data) === 'object') {
               this.setState({
                   firstName:res.data.firstName,
                   lastName:res.data.lastName,
                   address:res.data.address,
                   picture:res.data.picture,
               })
           }
           })
        .catch(err =>console.log(err));
        }
    }

    handleSubmit=(event)=>{
        event.preventDefault();
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.post(`${Home}auth/updateProfile`,{
               token: token,
               firstName:this.state.firstName,
               lastName:this.state.lastName,
               address:this.state.address,
               picture:this.state.picture2.length < 1 ? null : this.state.picture2,
            })
           .then(res => {
             console.log(res);
           this.setState({laoding:false,done:'WHOCODED_DONE'});
           if (res.data.success) {
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        }
    }

    componentDidMount(){
          this.LoadEditData();
          this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            <>
            <Toaster />
            <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                
                <div className="table-responsive">
                <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>Edit Your Profile</h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow pull-right">Return</button>
                            </div>
                        </div>
                    </div>
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 <>
                 
                 <center>
                                            <div className="form-group mb-2" id="st-up">
                                            <ImageUploading onChange={this.onChangeImage} maxNumber={maxNumber} multiple>
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                            // write your building UI
                                            <div>
                                            {this.state.picture2.length < 1 ?
                                            <>
                                            <button type="button" className="btn btn-primary shadow-sm d-flex" onClick={onImageUpload}>
                                              <MaterialIcon color="#ffffff" icon="add" />
                                            </button>
                                                <div className="avatar avatar-xxl">
                                                    <img className="rounded-circle shadow-lg sty" src={this.state.details.picture} />
                                                    </div>
                                            </>
                                            :''
                                            }
                                                
                                            <div className="row">
                                                {imageList.map(image => (
                                                <div className="col-md-12 p-2" key={image.key}>
                                                <button type="button" className="st-btn btn-primary2 shadow-sm mt-2" onClick={image.onUpdate}>Change</button>
                                                    <div className="avatar avatar-xxl">
                                                    <img className="rounded-circle shadow-lg sty" src={image.dataURL} />
                                                    </div>
                                                    <div>
                                                
                                                    </div>
                                                    
                                                
                                                </div>
                                                
                                                ))}
                                            </div>
                                            </div>
                                            )}
                                    </ImageUploading>
                                    </div>
                                            </center>

                      <div className=" table-responsive mt-5">
                          <form onSubmit={this.handleSubmit}>
                          <div className="form-group">
                              <label>First Name</label>
                              <input 
                              name="firstName"
                              value={this.state.firstName}
                              onChange={this.handleChange}
                              type="text" 
                              className="form-control" />
                          </div>
                          <div className="form-group">
                              <label>Last Name</label>
                              <input 
                              name="lastName"
                              value={this.state.lastName}
                              onChange={this.handleChange}
                              type="text" 
                              className="form-control" />
                          </div>
                          <div className="form-group">
                              <label>Address</label>
                              <input 
                              name="address"
                              value={this.state.address}
                              onChange={this.handleChange}
                              type="text" 
                              className="form-control" />
                          </div>
                          {this.state.laoding ? 
                          <Spinner />
                        :
                        <button className="btn btn-primary shadow">Save Changes</button>
                        
                        }
                         
                          </form>
                          </div>
                      
                      
                      </>
        } 
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (EditProfile);