  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import Rating from 'react-rating';
import success from '../img/success.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IosMail from 'react-ionicons/lib/IosMail'
import Toaster from '../global/Toaster';
import { number_verified } from '../store/actions/NumberV';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false,
            message:"",
            laoding:false
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }


      handleSubmit=(event)=>{
        event.preventDefault();
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true});
            axios.post(`${Home}auth/verifyNumber`,{
               token: token,
               code:this.state.comment,
            })
           .then(res => {
             console.log(res);
           this.setState({loading:false,});
           if (res.data.success) {
               this.setState({done:'WHOCODED_DONE',})
               this.props.dispatch(number_verified(true));
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
               this.setState({message:res.data.message})
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        }
    }

    ResendSMS=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            axios.get(`${Home}auth/resendSMS`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({laoding:false});
           if (res.data.success) {
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           
           })
        .catch(err =>console.clear());
        }
    }


    componentDidMount(){
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            <>
            <Toaster />
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404d4',overflow:'scroll',zIndex:'1111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> <IosMail onClick={() => alert('Hi!')} shake={true} fontSize="31px" color="#000" /> Verify Your Number </h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                    {this.state.done === 'WHOCODED_DONE'?
                            <div className="st-display-flex">
                                <center>
                                <LazyLoadImage
                                  alt={"whocoded"}
                                  effect="blur"
                                  src={success}
                                  style={{width:'40%'}}
                                  />
                                    <div className="text-center">
                                        Number Verified. <br></br>
                                        <button onClick={this.props.close} className="btn btn-sm btn-danger shadow st-sm-btn mb-2" style={{width:'fit-content'}}>Return</button>
                                    </div>
                                </center>
                            </div>
                            :
                      <div>
                        <div className="form-group mt-2">
                            
                             <div className="form-group mt-2">
                                 <label>Enter code sent to your number</label>
                                 <input className="form-control" value={this.state.comment} onChange={this.handleChange} name="comment" placeholder="type verication code" />
                                 <small className="text-danger">{this.state.message}</small>
                               <span style={{fontSize:'14px'}}>Didn't recieve the SMS?</span> {this.state.laoding ? 'Sending....' : <Link onClick={()=>this.ResendSMS()} to="#" className="link" style={{fontSize:'14px'}}> Resend SMS</Link>} 
                            </div>
                            
                        </div>

                      </div>
                  }
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Verify now</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>
                    </div>
                </div>
                </div>
                </>
           
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps) (Verify);