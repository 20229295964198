import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TopNav from '../components/navs/TopNav';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { Home } from '../global/Home';
import Toaster from '../global/Toaster';
import Spinner from '../components/loader/Spinner';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style:"bg-light",
            sroller:false,
            name:"",
            subject:"",
            number:"",
            description:"",
            category:"",
            message:"",
            laoding:false
        }
    }

    trackScrolling = () => {
        if (window.pageYOffset >= 200) {
          this.setState({sroller:true});
        }else{
          this.setState({sroller:false});
        }
      }
      handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }
      handleSubmit=(event)=>{
        event.preventDefault();
        this.setState({laoding:true});
            Axios.post(`${Home}newticket`,{
               name:this.state.name,
               category:this.state.category,
               number:this.state.number,
               subject:this.state.subject,
               message:this.state.description,
            })
           .then(res => {
             console.log(res);
           this.setState({laoding:false,done:'WHOCODED_DONE'});
           if (res.data.success) {
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
    }
      
      changeStyle(){
        if (this.state.style === 'bg-light') {
          this.setState({style:"bg-primary"});
        }else if (this.state.style === 'bg-primary') {
          this.setState({style:"bg-light"});
        }
      
   }
       
      
      componentDidMount(){
        document.addEventListener('scroll', this.trackScrolling);
          this.interval = setInterval(() => this.changeStyle(), 6000);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          document.removeEventListener('scroll', this.trackScrolling);
        }

    render() {
        return (
            <div>
                <Toaster />
                <TopNav sticky={this.state.sroller? 'nav-sticky':''} />
        <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.4524568174193!2d3.8613671147757964!3d7.415072094650076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96927df261678e7c!2sAbewon%20Shopping%20Complex!5e0!3m2!1sen!2sng!4v1592400564531!5m2!1sen!2sng" width="100%" height="450" className="map-top" frameborder="0" style={{border:0,}} allowfullscreen="" aria-hidden="false" tabindex="0">

         </iframe>
        
        <div class="position-relative">
            <div class="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
            <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <span class="badge badge-pill badge-success aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">Features</span>
                            <h4 class="title my-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400">Communication Resources</h4>
                            <p class="text-muted para-desc mx-auto mb-0 aos-init" data-aos="fade-up" data-aos-duration="1800">Start working with <span class="text-primary font-weight-bold">Coinwells</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card catagories text-center rounded border-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                            <div class="card-body">
                                <img src="http://www.shreethemes.in/landrick/layouts/images/icon/chat.svg" class="avatar avatar-small mb-3" alt=""/>
                                <h5><a href="#" class="text-dark">Live Chat</a></h5>
                                <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not be distracted from making</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card catagories text-center rounded border-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1300">
                            <div class="card-body">
                                <img src="http://www.shreethemes.in/landrick/layouts/images/icon/customer-service.svg" class="avatar avatar-small mb-3" alt=""/>
                                <h5><a href="#" class="text-dark">Social</a></h5>
                                <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not be distracted from making</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                        <div class="card catagories text-center rounded border-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1900">
                            <div class="card-body">
                                <img src="http://www.shreethemes.in/landrick/layouts/images/icon/call.svg" class="avatar avatar-small mb-3" alt=""/>
                                <h5><a href="#" class="text-dark">Phone Supports</a></h5>
                                <p class="text-muted mb-0 mt-3">If the distribution of letters and 'words' is random, the reader will not be distracted from making</p>
                            </div>
                        </div>
                    </div>

                </div>



                <div class="container mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">Submit A Ticket</h4>
                            <p class="text-muted para-desc mb-0 mx-auto aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400">Start working with <span class="text-primary font-weight-bold">Coinwells</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-5 col-12 mt-4 pt-2">
                        <img src="http://www.shreethemes.in/landrick/layouts/images/customer/customer.png" data-aos="fade-up" data-aos-duration="1400" class="img-fluid aos-init aos-animate" alt=""/>
                    </div>

                    <div class="col-lg-6 col-md-7 col-12 mt-4 pt-2">
                        <div class="card rounded shadow border-0 ml-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400">
                            <div class="card-body p-4">
                            <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Your Name :</label>
                                                <input 
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                name="name" 
                                                id="name" type="text" 
                                                className="form-control" 
                                                placeholder="First Name :" />
                                            </div>
                                        </div>
        
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Your Email :</label>
                                                <input 
                                                value={this.state.number}
                                                onChange={this.handleChange}
                                                name="number" 
                                                id="email" 
                                                type="number" 
                                                className="form-control" placeholder="Your email :"/>
                                            </div> 
                                        </div>
        
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Reasons / Catagories :</label>
                                                <select 
                                                onChange={this.handleChange}
                                                name="category"
                                                className="form-control custom-select" 
                                                id="Sortbylist-Shop">
                                                    <option value="">Catagories</option>
                                                    <option value="General Question">General Question</option>
                                                    <option value="Bugs">Bugs</option>
                                                    <option value="Remote">Remote</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Subject :</label>
                                                
                                                <input 
                                                value={this.state.subject}
                                                onChange={this.handleChange}
                                                name="subject" id="subject" 
                                                className="form-control" 
                                                placeholder="Your subject :"/>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description :</label>
                                                <textarea 
                                                onChange={this.handleChange}
                                                value={this.state.description}
                                                name="description" rows="4" 
                                                className="form-control" 
                                                placeholder="Describe your problem :"></textarea>
                                            </div>
                                        </div>
                                        
        
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {this.state.laoding ?
                                            <Spinner />
                                        :
                                        <button type="submit" id="submit" name="send" className="btn btn-primary">Submit Now</button>
                                        }
                                            
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>


        </section>

        <Footer />
            </div>
        );
    }
}

export default Contact;