  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import Rating from 'react-rating';
import success from '../img/success.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IosLockOutline from 'react-ionicons/lib/IosLockOutline'
import validator from 'validator';
import Toaster from '../global/Toaster';


class ResetPass extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false,
            numberError:"",
            number:"",
            reset:"",
            code:"",
            password:"",
            password2:"",
            pasError:""
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }


      handleSubmit=(event)=>{
        event.preventDefault();
        let check = this.Validator();
        if (check) {
            this.setState({loading:true});
            axios.post(`${Home}resetpass`,{
               number:this.state.number,
            })
           .then(res => {
            //  console.log(res);
           if (res.data.success) {
            this.setState({loading:false,reset:'WHOCODED'});
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
               this.setState({loading:false})
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        }
        
    }

    handleSubmit2=(event)=>{
        event.preventDefault();
        this.setState({loading:true});
            axios.post(`${Home}checkResetCode`,{
               number:this.state.number,
               code:this.state.code
            })
           .then(res => {
             //console.log(res);
           if (res.data.success) {
            this.setState({loading:false,reset:'WHOCODED_DONE'});
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
               this.setState({loading:false})
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        
    }


    handleSubmit3=(event)=>{
        event.preventDefault();
        if (this.state.password !== this.state.password2) {
          this.setState({pasError:'Password didnt match'});
          return false;
        }
        this.setState({loading:true});
            axios.post(`${Home}changePassword`,{
               number:this.state.number,
               code:this.state.code,
               password:this.state.password
            })
           .then(res => {
             //console.log(res);
           if (res.data.success) {
            this.setState({loading:false,done:'WHOCODED_DONE'});
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
               this.setState({loading:false})
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        
    }

    Validator =()=>{
   
    
        if (!validator.isNumeric(this.state.number)) {
          this.setState({numberError:"Number must a number"})
        }else if (this.state.number.length !== 11) {
          this.setState({numberError:"Number must be 11 character length"})
        } else {
          this.setState({numberError:""})
        }
    
        if (!validator.isNumeric(this.state.number) || this.state.number.length !== 11) {
          return false;
        }else{
          return  true;
        }
      }


    componentDidMount(){
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            <>
            <Toaster />
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404f2',overflow:'scroll',zIndex:'111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> <IosLockOutline  beat={true} fontSize="31px" color="#000" /> Reset your password</h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                {this.state.reset === "WHOCODED" ?
                
                <form onSubmit={this.handleSubmit2}>
                    <div className="modal-body">
                    
                      <div>
                        <div className="form-group mt-2">
                             <div className="form-group mt-2">
                                 <label>Enter code</label>
                              <input  type="number" className="form-control" value={this.state.code} onChange={this.handleChange} name="code" required placeholder="Code" />
                            <small className="d-block">Verification code is sent to {this.state.number}</small>
                             
                            </div>
                        </div>

                      </div>
                  
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Submit Now</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>

                :''}

                {this.state.reset === "WHOCODED_DONE" ?
                
                <form onSubmit={this.handleSubmit3}>
                    <div className="modal-body">
                    {this.state.done === 'WHOCODED_DONE'?
                            <div className="st-display-flex">
                                <center>
                                <LazyLoadImage
                                  alt={"whocoded"}
                                  effect="blur"
                                  src={success}
                                  style={{width:'40%'}}
                                  />
                                    <div className="text-center">
                                        Password reset successful. You can now login with your new password <br></br>
                                        <button onClick={this.props.close} className="btn btn-sm btn-danger shadow st-sm-btn mb-2" style={{width:'fit-content'}}>Return</button>
                                    </div>
                                </center>
                            </div>
                            :
                      <div>
                        <div className="form-group mt-2">
                             <div className="form-group mt-2">
                                 <label>Choose a new password</label>
                              <input  type="password" className="form-control" value={this.state.password} onChange={this.handleChange} name="password" required placeholder="new password" />
                              <small className="text-danger d-block">{this.state.numberError}</small>
                             
                            </div>

                            <div className="form-group mt-2">
                                 <label>Confirm pasword</label>
                              <input  type="password" className="form-control" value={this.state.password2} onChange={this.handleChange} name="password2" required placeholder="Confirm password" />
                              <small className="text-danger d-block">{this.state.pasError}</small>
                             
                            </div>
                        </div>

                      </div>
                  }
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Reset Password</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>

                :''}
                {this.state.reset === ''?
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                    
                      <div>
                        <div className="form-group mt-2">
                             <div className="form-group mt-2">
                                 <label>Enter your phone number</label>
                              <input onBlur={this.Validator} type="number" className="form-control" value={this.state.number} onChange={this.handleChange} name="number" required placeholder="Phone number" />
                              <small className="text-danger d-block">{this.state.numberError}</small>
                              <small className="text-muted">Keep your phone ready for SMS</small>
                            </div>
                        </div>

                      </div>
                  
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Submit Now</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>
              :'' }
                    </div>
                </div>
                </div>
           </>
        );
    }
}

export default ResetPass;