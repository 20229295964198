import React, { Component } from 'react';
import img from '../img/user.png'
import { connect } from 'react-redux';
import { switch_content } from '../store/actions/SwitchContent';
import Axios from 'axios';
import { Home } from '../global/Home';
import { toast } from 'react-toastify';
import Toaster from '../global/Toaster';
import ImageUploading from "react-images-uploading";
import IosRadioButtonOn from 'react-ionicons/lib/IosRadioButtonOn'
import ImgsViewer from 'react-images-viewer'
import { animateScroll,Link } from "react-scroll";
import socketIOClient from "socket.io-client";
import io from 'socket.io-client'
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import * as Scroll from 'react-scroll';
import Spinner from '../global/Spinner';


const socket = io('https://nodecoin.coinwells.com/');

const maxNumber = 1;
let Events    = Scroll.Events;
let scrollSpy = Scroll.scrollSpy;
class FullChat extends Component {
    constructor(props) {
        super(props);
        this.state ={
            post:false,
            list:[],
            style:"",
            laoding:false,
            details:[],
            data:[],
            id:0,
            nextPage:[],
            message:"",
            picture2:[],
            upload:0,
            rates:[],
            btc:"",
            mo:false,
            r:0,
            view:false,
            pix:[],
            info:null,
            endpoint: "https://nodecoin.coinwells.com/",
            seleted:[],
            drage:false,
            stock:false,
            singlechat:null,
            activeDrags: 0,
            ElementId:0,
            deltaPosition: {
            x: 0, y: 0
            },
            controlledPosition: {
            x: -400, y: 200
            },
            lo2:false,
            hover:-1
                }
    }
    handleDrag = (e, ui) => {
        const {x, y} = this.state.deltaPosition;
        this.setState({
          deltaPosition: {
            x: x + ui.deltaX,
            y: y + ui.deltaY,
          }
        });
      };
    
      onStart = () => {
        this.setState({activeDrags: ++this.state.activeDrags});
      };
    onStop = () => {
        this.setState({activeDrags: --this.state.activeDrags,drage:true,stock:true});
        
      };
    
      // For controlled component
      adjustXPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {x, y} = this.state.controlledPosition;
        this.setState({controlledPosition: {x: x - 10, y}});
      };
    
      adjustYPos = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {controlledPosition} = this.state;
        const {x, y} = controlledPosition;
        this.setState({controlledPosition: {x, y: y - 10}});
      };
    
      onControlledDrag = (e, position) => {
        const {x, y} = position;
        this.setState({controlledPosition: {x, y}});
      };
    
      onControlledDragStop = (e, position) => {
        this.onControlledDrag(e, position);
        this.onStop();
      };

      ChooseChat=(index)=>{
        if (this.state.data.length > 0) {
            this.setState({drage:true,singlechat:this.state.data[index]})
        }
      }
    scrollToBottom() {
        
        animateScroll.scrollToBottom({
          containerId: "stephen-whocoded"
        });
        // animateScroll.scrollMore({
        //     offset:300,
        //     smooth: true,
        //     containerId: "stephen-whocoded"
        //   });
        // animateScroll.scrollTo(200,{containerId: "stephen-whocoded"})
    }

    ScrollToChat(ElementId){
        this.setState({ElementId:ElementId});
        if (document.getElementById(ElementId)) {
            let id = document.getElementById(ElementId).getBoundingClientRect();
            let number = 0;
            if (id.top <= 100) {
                number = id.top;
            }else if (id.top >= 101 && id.top <= 200) {
                number = id.top;
            }else if (id.top >= 201 && id.top <= 301) {
                number = 9000;
            }
            console.log(id.top,number);
            // animateScroll.scrollTo(number,{containerId: "stephen-whocoded"});
            // this.interval = setTimeout(() => animateScroll.scrollTo(id.top,{containerId: "stephen-whocoded"}), 850);
        }
    }
    LoadData=()=>{
        Axios.get(`${Home}Notice`,{
        })
       .then(res => {
        //  console.log(res);
       this.setState({r:res.data.active});
       })
    .catch(err =>console.log(err));
}
    LoadRates=()=>{
            Axios.get(`${Home}rates2`,{
            })
           .then(res => {
           this.setState({rates:res.data});
           //this.LoadBTC();
           })
        .catch(err =>console.log(err));
    }
    LoadBTC=()=>{
        Axios.get(`${Home}btc`,{
          })
         .then(res => {
         this.setState({btc:res.data,});
         })
    }
    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }

      AumaticRespons=(message)=>{
        let vara = "";
        if (message === 'Rate') {
          if (this.state.r === 0) {
            vara = 'We are currently offline';
          }else{
            if (this.state.rates.length > 0) {
                vara = `${this.state.rates[0].title}: ${this.state.rates[0].text} \n
                ${this.state.rates[1].title}: ${this.state.rates[1].text} \n
                ${this.state.rates[2].title}: ${this.state.rates[2].text} \n
                ${this.state.rates[3].title}: ${this.state.rates[3].text} \n`;
            }
          }
            
        }else if (message === 'Blockchain') {
          if (this.state.r === 0) {
            vara = 'We are currently offline';
          }else{
            if (this.state.rates.length > 0) {
                        vara = `${this.state.rates[0].address} `;
                }
          }
            
        }else if (message === 'BITCOIN SELL') {
            if (this.state.rates.length > 0) {
                vara = `We sell at ${this.state.rates[0].buy} `;
            }
        }else if (message === 'BITCOIN BANK') {
            if (this.state.rates.length > 0) {
                vara = `Bank Name: ${this.state.rates[0].bank}, Account Name: ${this.state.rates[0].acname}, Account Number: ${this.state.rates[0].acnumber} `;
            }
        }else if (message === 'Paxful') {
          if (this.state.r === 0) {
            vara = 'We are currently offline';
          }else{
            if (this.state.rates.length > 0) {
                  vara = `Our Paxful wallet is 32dEkytsJNRV3W268stfrAQtVhV7PZykts `;
              }
          }
        
        }else if (message === 'RMB BUY') {
            if (this.state.rates.length > 0) {
                vara = `We buy at ${this.state.rates[2].sell} `;
            }
        }else if (message === 'RMB SELL') {
            if (this.state.rates.length > 0) {
                vara = `We buy at ${this.state.rates[2].buy} `;
            }
        }else if (message === 'RMB BANK') {
            if (this.state.rates.length > 0) {
                vara = `Bank Name: ${this.state.rates[2].bank}, Account Name: ${this.state.rates[2].acname}, Account Number: ${this.state.rates[2].acnumber} `;
            }
        }else if (message === 'PM') {
          if (this.state.r === 0) {
            vara = 'We are currently offline';
          }else{
            if (this.state.rates.length > 0) {
                vara = ` ${this.state.rates[1].address} `;
              }
          }
            
        }else if (message === 'PERFECT MONEY BUY') {
            if (this.state.rates.length > 0) {
                vara = `We buy at ${this.state.rates[1].sell} `;
            }
        }else if (message === 'PERFECT MONEY SELL') {
            if (this.state.rates.length > 0) {
                vara = `We sell at ${this.state.rates[1].buy} `;
            }
        }else if (message === 'PERFECT MONEY BANK') {
            if (this.state.rates.length > 0) {
                vara = `Bank Name: ${this.state.rates[1].bank}, Account Name: ${this.state.rates[1].acname}, Account Number: ${this.state.rates[1].acnumber} `;
            }
        }else if (message === 'BTC RATES') {
            if (typeof(this.state.btc) === 'object') {
                vara = `Latest: ${this.state.btc.last} | Highest: ${this.state.btc.high} | Bid: ${this.state.btc.bid} `;
            }
        }
        let  data = {
            myChat:false,
            message:vara
        }
        if (message === 'Blockchain' || message === 'Rate' || message === 'Paxful' 
        || message === 'PM' || message === 'RMB ADDRESS' || message === 'RMB BUY' || message === 'RMB SELL'
        || message === 'RMB BANK' || message === 'PERFECT MONEY ADDRESS' || message === 'PERFECT MONEY BUY' 
        || message === 'PERFECT MONEY SELL' || message === 'PERFECT MONEY BANK' || message === 'BTC RATES') {
            if (vara !== "") {
                this.setState({data:this.state.data.concat(data)})
            }
            
        }
        
      }

      handleSubmit2=(event)=>{
        if (event) {
            event.preventDefault(); 
        }
        if (this.state.post) {
            toast.error("Trying to send previous message...Your network may be slow ",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
            return false;
        }
        
        if (this.state.id === 0) {
            toast.error("Please select a user before sending message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
                this.setState({post:false})
            return false;
        }
        
        let token = "";
        if (this.state.message.length > 0) {
            this.setState({post:true})
            if (localStorage.getItem('userToken')) {
                token = JSON.parse(localStorage.getItem('userToken'));
                this.AumaticRespons(this.state.message);
                socket.emit('newMessage',{from:this.state.info.id,to:this.state.id,message:this.state.message,rep:this.state.singlechat !== null ? this.state.singlechat.id:0})
               this.setState({laoding2:false,message:"",post:false});
               this.setState({drage:false,stock:false,singlechat:null});
               this.SocketStopTyping();
               

               
            //     this.setState({laoding2:true});
            //     Axios.post(`${Home}auth/postChat`,{
            //       token: token,
            //       message:this.state.message,
            //       id:this.state.id
            //     })
            //    .then(res => {
            //        console.log(res.data)
            //     socket.emit('newMessage',{from:this.state.info.id,to:this.state.id,message:this.state.message})
            //    this.setState({laoding2:false,message:"",data:this.state.data.concat(res.data.data),post:false});
            //    this.AumaticRespons(res.data.data.message);
            //    })
            // .catch(err =>this.setState({post:false},
            //     toast.error("Network Error... Try again with better network",{
            //         position: "top-right",
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: false,
            //         draggable: false
            //         })
            //     ));
            }
        }else{
            toast.error("Can't send empty message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
        } 
    }
    hover=(name)=>{
        if (this.state.style == 'st--show') {
            this.setState({style:''})
        }else{
            this.setState({style:name})
        } 
    }
    
    ChangeHandle=(name)=>{
        this.props.dispatch(switch_content(name))
    }
    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/listStaffChat`,{
               params:{ token: token}
            })
           .then(res => {
             console.log('Staff: ',res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>console.log(err));
        }
    }


    LoadChats=(id)=>{
        this.setState({mo:true});
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({lo2:true,id:id});
            Axios.get(`${Home}auth/mychats/${id}`,{
              params:{token: token}
            })
           .then(res => {
               console.log(res);
           this.setState({data:res.data,lo2:false});
           if(res.data.length > 0){
            this.setState({nextPage:res.data[0].nextPageUrl});
            this.scrollToBottom()
            }else{
                this.setState({nextPage:null});
            }
           
           })
        .catch(err =>console.log(err));
        }
    }
    Refresh=()=>{
        let token = "";
        if (localStorage.getItem('userToken') && this.state.id !== 0) {
            token = JSON.parse(localStorage.getItem('userToken'));
            Axios.get(`${Home}auth/mychatsRefresh/${this.state.id}`,{
             params:{token: token}
            })
           .then(res => {
               if (res.data.length > 0) {
                this.setState({data:this.state.data.concat(res.data)});
               }
           })
        .catch(err =>this.Relogin());
        }
    }

    Upload=()=>{
        let token = "";
        if (this.state.picture2.length > 0 && this.state.id !== 0) {
            if (localStorage.getItem('userToken')) {
                token = JSON.parse(localStorage.getItem('userToken'));
                this.setState({uploading:true});
                Axios.post(`${Home}auth/uploadImageChat`,{
                  token: token,
                  picture:this.state.picture2,
                  id:this.state.id
                },{
                    onUploadProgress: ProgressEvent =>{
                        let MyProgress = Math.round(ProgressEvent.loaded/ProgressEvent.total *100);
                
                       // console.log('Progress: '+ Math.round(ProgressEvent.loaded/ProgressEvent.total *100)+" %");
                        if(MyProgress < 99){
                            this.setState({upload:MyProgress})
                        }else{
                            this.setState({upload:0})
                        }
                        
                      }
                })
               .then(res => {
                // console.log(res)
               this.setState({uploading:false,
                message:"",
                data:this.state.data.concat(res.data.data),
                picture2:[]
            });
            this.hover('st--show');
               })
            .catch(err =>this.Relogin());
            }
        }else{
            toast.error("Can't send empty message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
        } 
    }
    trackScrolling = () => {
        // console.log(window.pageYOffset);
        // let token = "";
        // let nextPage = this.state.nextPage;
        // if (nextPage !==null) {
        //  if (!this.state.isPaginating) {
        //      this.setState({isPaginating:true});
        //      if (localStorage.getItem('userToken')) {
        //          token = JSON.parse(localStorage.getItem('userToken'));
        //          Axios.get(nextPage,{
        //              params:{token: token}
        //          })
        //          .then(res => {
                 
        //          if (res.data.length > 0) {
        //              for (let index = 0; index < res.data.length; index++) {
        //                  this.state.data.unshift(res.data[index]);
        //              }
        //              this.setState({nextPage:res.data[0].nextPageUrl, data: this.state.data});
        //          }else{
        //              this.setState({nextPage:null});
        //          }
                 
        //          this.setState({isPaginating:false,});
        //          })
        //      }
             
        //     }
        // }
  
    }
    onChangeImage = imageList => {
        this.setState({picture2:imageList})
      }
    compare( a, b ) {
        if ( a.id < b.id ){
          return -1;
        }
        if ( a.id > b.id ){
          return 1;
        }
        return 0;
      }

      LoadDe=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            // this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
               params:{ token: token}
            })
           .then(res => {
             console.log(res)
           this.setState({info:res.data});
           this.SocketLogin();
           
           
           })
        .catch(err =>console.log(err));
        }
    }

    SocketLogin=()=>{
        if (this.state.info !==null && this.state.info.id) {
            socket.emit('Login',{id:this.state.info.id,username:this.state.info.name})
        }
        
    }
    SocketTyping=()=>{
        if (this.state.id !== 0) {
            socket.emit('StartTyping',{from:this.state.info.id,to:this.state.id});
        }
    }
    SocketStopTyping=()=>{
        if (this.state.id !== 0) {
            socket.emit('StartStopTyping',{from:this.state.info.id,to:this.state.id});
        }
    }
    AddArray=(id)=>{
        let values = this.state.seleted.concat(id);
        let uniqueItems = Array.from(new Set(values));
        this.setState({seleted:uniqueItems});
        // console.log(id,this.state)
     }
 
     RemoveArray=(id)=>{
         if (this.state.seleted.includes(id)) {
             let values = this.state.seleted;
             let index = values.indexOf(id);
             this.state.seleted.splice(index, 1);
             this.setState({seleted:this.state.seleted});
         }
        //  console.log(id,this.state)
      }

    componentDidMount(){
        this.interval = setInterval(() => this.Refresh(), 2000);
        Events.scrollEvent.register('begin', function(to, element) {
            console.log('begin', arguments);
          });
          scrollSpy.update();
        document.addEventListener('scroll', this.trackScrolling);
        this.LoadRates();
        // this.LoadEditData();
        this.LoadData();
        this.LoadDe();
        // this.interval = setInterval(() => this.Refresh(), 2000);
        const {endpoint} = this.state;
        socket.on("connection", data => {
            this.setState({response: data})
            console.log('Socet',data)
            this.SocketLogin();
        });  
        socket.on('details',data=>
        console.log('Connection',data)
        // socket.emit('Login',{id:22,name:'Stephen'})
        );
        socket.on('onNewMessage',data=>{
        console.log('Recieve mail',data)
        this.setState({lo:true})
        this.interval = setInterval(() => socket.emit('loadStaff',{id:this.state.info.id}), 2000);
            if (this.state.id !== 0) {
                this.setState({data:this.state.data.concat(data)})
            }
        }
         )

         socket.on("onStartTyping", data => {
            if (this.state.details.length > 0) {
                this.AddArray(data) 
            }
            console.log(data)
        });
        socket.on("onStartStopTyping", data => {
            if (this.state.details.length > 0) {
                this.RemoveArray(data)
             }
            console.log(data)
        });

        socket.on("onMessageSent", data => {
            this.setState({data:this.state.data.concat(data)});
            this.setState({lo:true})
            this.interval = setInterval(() => socket.emit('loadStaff',{id:this.state.info.id}), 2000);
            console.log('Message Delivered: ',data)
        });
       

        socket.on("loggedIn", data => {
            console.log('LoggedIn. Load Staff...: ',data)
            this.setState({lo:true})
            this.interval = setInterval(() => socket.emit('loadStaff',{id:this.state.info.id}), 2000);
        });

        socket.on("staffReturn", data => {
            this.setState({details:data,lo:false});
            console.log('staffReturn: ',data)
        });

        socket.on("SQLResult", data => {
            console.log('SQLResult: ',data)
        });
        socket.on("listConnect", data => {
            console.log('listConnect: ',data)
        });
        socket.on("Error", data => {
            console.log('Error: ',data)
        });
        

        socket.on('onConnected',data=>{
        console.log('Connected',data)
        this.SocketLogin();
        }
        )

        socket.on('ss',data=>
        console.log('Socket',data)
        )
        }
        componentWillUnmount() {
            clearInterval(this.interval);
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        }
       

        gotoNextImg =()=>{
            // this.setState({index:1})
         }
     
         gotoPrevImg=()=>{
             
         }
         closeImgsViewer=()=>{
            this.setState({view:false});
            // this.props.dispatch(view_image(false));
            // this.props.dispatch(current_image([]));
        }

        ViewImageHandler=(imageArray)=>{
            
            const IMG_SET = [
                {
                  src: imageArray,
                  srcSet: imageArray,
                }
              ]
    
            this.setState({pix:IMG_SET,view:!this.state.view})
        }

         predicateBy=(prop)=>{
            return function(a,b){
               if (a[prop] < b[prop]){
                   return 1;
               } else if(a[prop] > b[prop]){
                   return -1;
               }
               return 0;
            }
         }

    render() {
        let data = this.state.data.sort(this.compare);
        // console.log(window.pageYOffset);
        // let data3 = this.state.details.sort(this.compare);
        let Sorted = this.state.details.sort(this.predicateBy("chatId") );
      
        
        return (
            <div>
                {this.state.view ?
                <ImgsViewer
                    showImgCount={false}
                    backdropCloseable={true}
                        imgs={this.state.pix}
                        currImg={0}
                        isOpen={this.state.view}
                        onClickPrev={this.gotoPrevImg}
                        onClickNext={this.gotoNextImg}
                        onClose={this.closeImgsViewer}
                    />
            :''}
                <Toaster />
                <div  className="st--wrapper">
                    <div className="st--container">
                        <div className={`left ${this.state.mo ? 'w-ll' : ''}`}>
                            <div className="top">
                               <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow btn-block">Return</button>
                            </div>
                            
                            <ul className="people st-ul">
                                {this.state.lo ?
                              <center>
                                   <Spinner />
                              </center> 
                            :''}
                                {
                                this.state.details.length < 1 ?
                                ''
                                :
                                Sorted.map(chat=>
                                <li onClick={()=>this.LoadChats(chat.id)} key={chat.id} className={`person ${this.state.id === chat.id ? 'activew':''}`}>
                                    <img src={img} alt="" />
                                    <span className="name">{chat.name}</span>
                                    {chat.isOnline ?
                                    <span>
                                    <IosRadioButtonOn beat={true} fontSize="20px" color="#43853d" />
                                    </span>
                                :
                                ''}
                                    {this.state.seleted.includes(chat.id)?
                                    <span className="text-success d-block"><strong><i>Typing...</i></strong></span>
                                    :
                                    <span className="preview">{chat.lastmessage}</span>
                                    }
                                    
                                </li>
                                    )}
                                
                            </ul>
                        </div>

                        <div className={`right ${this.state.mo ? 'w-rii' : ''}`}>
                
                         <div id="stephen-whocoded" className="chat">
                             {this.state.lo2?
                             <center>
                                 <Spinner />
                             </center>
                             
                            : 
                            <>
                         {this.state.mo ?
                         <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow btn-block d-mo mb-3">Return</button>
                        :''}
                        {this.state.data.length < 1 ?
                      <div className="alert alert-primary text-center">
                          Select a chat
                      </div>
                      :
                      <>
                      {this.state.nextPage !== null ?
                                      <center>
                                          {this.state.isPaginating ? 
                                          'Loading More...'
                                        :
                                        <div id="1" name="test1">
                                         <button onClick={this.trackScrolling} className="btn btn-sm  btn-outline-primary">Load more</button>
                                        </div>
                                        }
                                        
                                      </center>
                                      
                                    :''}
                      {this.state.data.map((chats,i)=>
                        
                        chats.myChat?
                        // <Draggable  grid={this.state.stock?[0,-100]:null} key={chats.id} axis="x" bounds={{top: -100, left: -100, right: 0, bottom: 100}} {...dragHandlers}>
                        chats.parent > 0 ?
                        <div key={i}>
                        <div onMouseEnter={()=>this.setState({hover:i})} onMouseLeave={()=>this.setState({hover:-1})} id={`${chats.id}`} onClick={()=>this.ScrollToChat(chats.parent)} className={`bubble reply right ${this.state.ElementId === chats.id? 'st-acc':''}`}>{chats.parentMessage}</div>
                     <div onDoubleClick={()=>this.ChooseChat(i)} className="bubble me">
                     {chats.image === 1 ?
                     <img onClick={()=>this.ViewImageHandler(chats.message)} src={chats.message} className="img-fluid img-thumbnail shadow nn ddd" style={{width:'200px',height:'200px',cursor:'pointer'}} />
                     
                 :
                 chats.message
                 }
                 {this.state.hover === i ?
                    <span className="date badge badge-warning timee">{chats.time}</span>
                :''}
                    </div> 
                    </div>


                        :

                        
                        <div  key={i}>
                        <div onMouseEnter={()=>this.setState({hover:i})} onMouseLeave={()=>this.setState({hover:-1})} id={`${chats.id}`} onDoubleClick={()=>this.ChooseChat(i)} className={`bubble me ${this.state.ElementId === chats.id? 'st-acc':''}` }>
                        {chats.image === 1 ?
                        <img onClick={()=>this.ViewImageHandler(chats.message)} src={chats.message} className="img-fluid img-thumbnail shadow nn ddd" style={{width:'200px',height:'200px',cursor:'pointer'}} />
                        
                    :
                    chats.message
                    }
                    {this.state.hover === i ?
                    <span className="date badge badge-warning timee">{chats.time}</span>
                :''}
                       </div> 
                       </div>
                    //    </Draggable>
                        :







                        chats.parent > 0 ?
                        <div key={i}>
                         <div onMouseEnter={()=>this.setState({hover:i})} onMouseLeave={()=>this.setState({hover:-1})} id={`${chats.id}`} onClick={()=>this.ScrollToChat(chats.parent)} className={`bubble reply ${this.state.ElementId === chats.id? 'st-acc':''}`}>{chats.parentMessage}</div>
                     <div onDoubleClick={()=>this.ChooseChat(i)} className="bubble you">
                     {chats.image === 1 ?
                     <img onClick={()=>this.ViewImageHandler(chats.message)} src={chats.message} className="img-fluid img-thumbnail shadow nn ddd" style={{width:'200px',height:'200px',cursor:'pointer'}} />
                     
                 :
                 chats.message
                 }
                 {this.state.hover === i ?
                    <span className="date badge badge-warning timee">{chats.time}</span>
                :''}
                    </div> 
                    </div>


                        :

                        
                        <div  key={i}>
                            {chats.parent > 0 ?
                           <div onMouseEnter={()=>this.setState({hover:i})} onMouseLeave={()=>this.setState({hover:-1})} id={`${chats.id}`} onClick={()=>this.ScrollToChat(chats.parent)} className={`bubble reply right ${this.state.ElementId === chats.id? 'st-acc':''}`}>{chats.parentMessage}</div>  
                        :''}
                        <div id={`${chats.id}`} onDoubleClick={()=>this.ChooseChat(i)} className={`bubble you ${this.state.ElementId === chats.id? 'st-acc':''}`}>
                        {chats.image === 1 ?
                        <img onClick={()=>this.ViewImageHandler(chats.message)} src={chats.message} className="img-fluid img-thumbnail shadow nn ddd" style={{width:'200px',height:'200px',cursor:'pointer'}} />
                        
                    :
                    chats.message
                    }
                    {this.state.hover === i ?
                    <span className="date badge badge-warning timee">{chats.time}</span>
                :''}
                       </div> 
                       </div>

                        ) }
                      </> 
                    }

                   </>
                   }
                         </div>

                         {/* <Draggable axis="x" bounds={{top: -100, left: -100, right: 0, bottom: 100}} {...dragHandlers}>
                        <div className="box cursor-x">I can only be dragged horizonally (x axis)</div>
                        </Draggable> */}
                        {this.state.drage?
                        <div onClick={()=>this.setState({drage:false,stock:false,singlechat:null})} className="alert alert-secondary st-c-rep">
                          <i> {this.state.singlechat !== null? this.state.singlechat.message:''}</i> 
                          <span className="fa fa-trash-o text-white btn btn-danger ant-btn-round stu"></span>
                        </div>
                    :''}
                        
                         <form onSubmit={this.handleSubmit2} className={`write ${this.state.mo ? 'w-foot' : ''}`}>
                            <a href="#" className="write-link attach"></a>
                            <input onKeyDown={()=>this.SocketTyping()} onBlur={()=>this.SocketStopTyping()} value={this.state.message} name="message" onChange={this.handleChange} type="text" />
                            <a href="#" className="write-link send">
                                <i className="fa fa-send st-send-i first-right"></i>
                            </a>
                            <a onClick={()=>this.hover('st--show')}  href="#" className="write-link send">
                                <i className="fa fa-file-image-o st-send-i"></i>
                            </a>
                            
                         </form>
                         <div className={`st-pop shadow ${this.state.style}`}>
                             {/* <button className="btn btn-light btn-sm shadow m-1">
                                 <i className="fa fa-instagram st-con"></i>
                            </button> */}
                            <div className="form-group mb-2" id="st-up">
                                            <ImageUploading onChange={this.onChangeImage} maxNumber={maxNumber} multiple>
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                            // write your building UI
                                            <div>
                                            {this.state.picture2.length < 1 ?
                                            <center>
                                            <button type="button" className="btn btn-light shadow d-flex m-1" onClick={onImageUpload}>
                                              choose a picture
                                            </button>
                                               
                                            </center>
                                            :''
                                            }
                                                
                                            <div className="row">
                                                {imageList.map(image => (
                                                <div className="col-md-12 p-2" key={image.key}>
                                                <button type="button" className="st-btn btn-danger shadow-sm m-2" onClick={onImageRemoveAll}>Change</button>
                                                    <div className="avatar avatar-xxl m-2">
                                                    <img className="rounded-circl shadow-lg sty" src={image.dataURL} />
                                                    </div>
                                                    <div>
                                                
                                                    </div>
                                                    {this.state.uploading ? 
                                                    <button disabled={true} className="btn btn-secondary disabled shadow m-2">Uploading... Please wait</button>
                                                :
                                                <button onClick={this.Upload} className="btn btn-primary shadow m-2"> Send Image</button>
                                                }
                                                
                                                </div>
                                                
                                                ))}
                                            </div>
                                            </div>
                                            )}
                                    </ImageUploading>
                                    </div>
                            
                         </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (FullChat);