  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import success from '../img/success.png';
import Spinner from './loader/Spinner';
import Axios from 'axios';

class AccountDetailsPop extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }
    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}rates`,{
            })
           .then(res => {
             console.log(res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>console.log(err));
        }
    }


    componentDidMount(){
        this.LoadEditData()
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404d4',overflow:'scroll',zIndex:'11111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">  Our Account Details</h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div className="table-responsive">
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 <>
                 
                      <div className=" table-responsive mt-5">
                         <div className="row">
                             
                                 <div className="col-md-12">
                                     <div>
                                     <div className="card shadow-lg" style={{minHeight:'360px'}}>
                                        
                                        <div className="card-body">
                                            <small>Here is our account details</small>
                                            <table className="table-bordered table-hover table">
                                                
                                                    {this.state.details.length > 0 ?
                                                    <tbody>
                                                     <tr>
                                                     <th>Account Name</th>
                                                     <td>{this.state.details[0].acname}</td>
                                                 </tr>
                                                 <tr>
                                                     <th>Bank Name</th>
                                                     <td>{this.state.details[0].bank}</td>
                                                 </tr>
                                                 <tr>
                                                     <th>Account  Number <br></br></th>
                                                     <td>{this.state.details[0].acnumber}</td>
                                                 </tr>
                                                 
                                                 </tbody>
                                                :
                                                
                                                ''}
                                                 
                                            </table>
                                            
                                        </div>
                                        </div>
                                     </div>
                                 </div>
                                 
                             </div>
                            
                             
                            
                         </div>
                     
                      </>
        } 
                </div>


                    </div>
                </div>
                </div>
           
        );
    }
}

export default AccountDetailsPop;