import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/user.png'

class Procedures extends Component {
    constructor(props) {
        super(props);
        this.state={
            style:"st-hover2",
            details:"",
            laoding:false
        }
    }
    
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    ChangeHandle=(name)=>{
        this.props.dispatch(switch_content(name))
       // this.setState({current:name})
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}rates`,{
            })
           .then(res => {
             console.log(res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>console.log(err));
        }
    }

    componentDidMount(){
        this.LoadEditData();
          this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        return (
            <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                <div className="table-responsive">
                <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>Payment Procedures</h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow pull-right">Return</button>
                                
                            </div>
                        </div>
                    </div>
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 <>
                 
                    <center>
                        <div className="avatar avatar-xxl">
                            <img className="rounded-circle shadow-lg sty App-logo" src={pix} />
                         </div>
                        </center>
                      <div className=" table-responsive mt-5">
                         
                             <div className="mt-4">
                             <h2>Note</h2>
                             <p>
                             1. We don't accept third party payment. The transaction must be initiated by you only.
                             </p>
                             
                                <p>
                                2. If you are making online transfer (via bank apps or web) to our bank accounts kindly write this in your transaction DESCRIPTION / REMARK / NARRATION " I 'your name' is buying E-C from Coinwells"....e.g I NJOKU IS BUYING E-C from coinwells..
                                </p>
                            
                                <p>
                                3. If you are making cash deposit in to our bank accounts, kindly write this on your teller. I 'your name' is buying E-C from Coinwells" e.g I Fola IS BUYING E-C from coinwells.
                                </p>
                            
                                <p>
                                4. If you are sending money to us via atm transfer into our bank accounts, kindly make sure you use an atm machine that dispenses receipt and write this on the receipt gotten from the atm. I 'your name' is buying E-C from Coinwells" e.g I MUSA IS BUYING E-C from coinwells.
                                </p>
                            
                                <p>
                                5. Kindly DON'T transfer to our bank accounts via mobile code. E.g *737*account number*amount*pin#
                                </p>
                           
                                <p>
                                6. We DON'T accept QUICK TELLER transfer
                                </p>
                            
                                <p>
                                7. Failure to adhere to the above instructions might delay your order endlessly.
                            Kindly NOTE your ORDER ID, then goto ACCOUNT and upload PAYMENT PROOF under MY ORDERS, for further enquiries you can reach us on what's app (+2348062365982)
                                </p>
                             </div>
                             
                            
                         </div>
                     
                      </>
        } 
                </div>
            </div>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (Procedures);