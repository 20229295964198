  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import Rating from 'react-rating';
import success from '../img/success.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MdStarHalf from 'react-ionicons/lib/MdStarHalf'

class RatingS extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }


      handleSubmit=(event)=>{
        event.preventDefault();
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true});
            axios.post(`${Home}auth/postReview`,{
               token: token,
               message:this.state.comment,
               rate:this.state.value
            })
           .then(res => {
             console.log(res);
           this.setState({loading:false,done:'WHOCODED_DONE'});
           if (res.data.success) {
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }else{
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>console.log(err));
        }
    }


    componentDidMount(){
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404d4',overflow:'scroll',zIndex:'11111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> <MdStarHalf onClick={() => alert('Hi!')} rotate={true} fontSize="31px" color="#000" /> Rate Us <strong>{this.props.name}{this.props.id}</strong></h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="modal-body">
                    {this.state.done === 'WHOCODED_DONE'?
                            <div className="st-display-flex">
                                <center>
                                <LazyLoadImage
                                  alt={"whocoded"}
                                  effect="blur"
                                  src={success}
                                  style={{width:'40%'}}
                                  />
                                    <div className="text-center">
                                        Rate submitted for {this.props.name}. <br></br>
                                        <button onClick={this.props.close} className="btn btn-sm btn-danger shadow st-sm-btn mb-2" style={{width:'fit-content'}}>Return</button>
                                    </div>
                                </center>
                            </div>
                            :
                      <div>
                        <div className="form-group mt-2">
                            <Rating 
                            onClick={this.handleClick}
                            initialRating={this.state.value}
                            emptySymbol="fa fa-star-o fa-2x stf-1"
                            fullSymbol="fa fa-star fa-2x text--primary stf-1"
                             />
                             <div className="form-group mt-2">
                                 <label>Type a review</label>
                            <textarea className="form-control" value={this.state.comment} onChange={this.handleChange} name="comment" required placeholder="Write something"></textarea>
                            </div>
                            
                        </div>

                      </div>
                  }
                    </div>
                    <div className="modal-footer">
                        {this.state.done === "WHOCODED_DONE" ?
                        ''
                    :
                    <>
                        <button onClick={this.props.close} type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        {this.state.loading ?
                        <button  type="button" disabled={true} className="btn btn-secondary" >Submiting...</button>
                    :
                    <button  type="submit" className="btn btn-primary" >Submit Rating</button>
                    }
                    </>
                 }
                        
                    </div>
                    </form>
                    </div>
                </div>
                </div>
           
        );
    }
}

export default RatingS;