import React, { Component } from 'react';
import './App.css';
import './css/theme.css';
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import Index from './pages/Index';
import HomePage from './pages/HomePage';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Sell from './pages/Sell';
import Buy from './pages/Buy';
import LogOut from './pages/LogOut';
import './css/chating.css'


class App extends Component {
  render(){
    // console.log(this.props);
    let login_layout = true;
    if (this.props.data.login_layout === 'STEPHEN_WHOCODED') {
      login_layout = false;
    }else{
      login_layout = true;
    }
   // console.log(login_layout);
  return (
    <>
          <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/login" exact component={Login} />
                <Route path="/register" exact component={Register} />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/dashboard/sell" exact component={Sell} />
                <Route path="/dashboard/buy" exact component={Buy} />
                <Route path="/logout" exact component={LogOut} />
                {/* <Route path="/assignments" exact component={AssignmentsPage} /> */}
                <Route render={function () {
                      return <div className=" App-header">
                            <h1>Page Not Found</h1>
                        </div>
                    }} />
            </Switch>
          
    
    </>
  );
   }
}

const mapStoreToProps = (store) =>{
  return{
     data:store
  }
}

export default connect(mapStoreToProps) (App);
