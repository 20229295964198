import React,{Component} from 'react'
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import bg from '../img/bg.png'
import bg1 from '../img/1.jpg'
import bg2 from '../img/2.jpg'
import bg3 from '../img/3.jpg'
 
class Carousels extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
        <Carousel
        autoPlay={true}
                showStatus={false}
                showIndicators={false}
        >
        <div>
            <img src={bg1} />
            <p className="legend">Bitcoin</p>
        </div>
        <div>
        <img src={bg2} />
            <p className="legend">Perfect Money</p>
        </div>
        <div>
          <img src={bg3} />
            <p className="legend">RMB</p>
        </div>
    </Carousel>
    );
  }
}

export default Carousels;