  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import Rating from 'react-rating';
import success from '../img/success.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LogoBitcoin from 'react-ionicons/lib/LogoBitcoin'
import Lives from './Lives';

class RatingPopMarket extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:""
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }

    loadData(){
        let token = "";
		if (localStorage.getItem('userDetails')) {
			token = JSON.parse(localStorage.getItem('userDetails'));
            this.setState({fetching:true});
			axios.post(Home+`auth/GetSingleEventTicket`,{
                token: token.token,
                id:this.props.id
			})
		   .then(res => {
			 console.log(res);
		   const details = res.data;
		   this.setState({ 
              details:details,
              fetching:false
              });
              
		   })
		.catch(err =>console.log(err));
		}
	
    }

    handleSubmit=(event)=>{
        event.preventDefault();
        let token = "";
		if (localStorage.getItem('userDetails')) {
			token = JSON.parse(localStorage.getItem('userDetails'));
            this.setState({fetching:true});
			axios.post(Home+`auth/RateAttendee`,{
                token: token.token,
                id:this.props.pId,
                value:this.state.value,
                comment:this.state.comment,
                userId:this.props.userId
			})
		   .then(res => {
			 console.log(res);
		   const details = res.data;
		   this.setState({ 
              fetching:false,
              done:"WHOCODED_DONE"
              });
              
		   })
		.catch(err =>console.log(err));
        }
        
    }


    componentDidMount(){
        this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404d4',overflow:'scroll',zIndex:'11111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> <LogoBitcoin onClick={() => alert('Hi!')} beat={true} fontSize="31px" color="#000" /> Current Rates </h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                  <div>
                      <Lives nohead={true} />
                  </div>
                    </div>
                </div>
                </div>
           
        );
    }
}

export default RatingPopMarket;