import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Home } from '../global/Home';
import Axios from 'axios';
import Spinner from './loader/Spinner';
import { login_again } from '../store/actions/Rlogin';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style:"st-hover2",
            loading:false,
            data:[]
        }
    }

    changeStyle=(name)=>{
        this.setState({style:name})
    }
    Relogin=()=>{
        this.props.dispatch(login_again(true));
    }

    LoadData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true});
            Axios.get(`${Home}auth/myorders`,{
               params:{token: token}
            })
           .then(res => {
            //  console.log(res);
           this.setState({loading:false,data:res.data});
           })
        .catch(err =>this.Relogin());
        }
    }

    componentDidMount(){
        this.LoadData()
        this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({style:''});
      }
    
    render() {
        return (
            <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                <div className="table-responsive">
                    <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>List of Orders</h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={this.props.close} className="btn btn-danger shadow pull-right">Return</button>
                            </div>
                        </div>
                    </div>
                    {this.state.loading ?
                    <div className="p-3">
                        <Spinner />
                    </div>
                
                :
                this.state.data.length < 1 ?
                 <div className="aler alert-warning shadow text-center p-2">
                     No order yet
                 </div>
                :
                    <table className="table table-striped table-bordered table-hover mt-5">
                        <thead>
                            <tr>
                                <th style={{width:'8%'}}></th>
                                <th>Currency</th>
                                <th>Order ID</th>
                                <th>Amount</th>
                                <th>type</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Date</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map(da=>
                                <tr key={da.id}>
                                <td>
                                    <img src={da.currencyIcon} className="rounded-circle avatar-small sty2 shadow" />
                                </td>
                                <td>{da.currency}</td>
                                  <td>{da.code}</td>
                                <td>{da.amount}</td>
                                <td>{da.type}</td>
                                <td>{da.total}</td>
                                <td>{da.status}</td>
                                <td>{da.date}</td>
                                 
                            </tr>
                                )}
                            
                            
                        </tbody>
                    </table>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (Orders);