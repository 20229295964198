import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import log from '../../img/LO.png'
import log2 from '../../img/LO2.png'
import chat from '../../img/2.png'
import RatingPopMarket from '../RatingPopMarket';
import IosAlertOutline from 'react-ionicons/lib/IosAlertOutline'
import MdWifi from 'react-ionicons/lib/MdWifi'
import ChatContainer from '../ChatContainer';
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import Axios from 'axios';
import { Home } from '../../global/Home';
import sound from '../../img/sound.mp3'
import { Offline, Online } from "react-detect-offline";
import io from 'socket.io-client'
import { toggle } from '../../store/actions/Toggle';
import { connect } from 'react-redux';

// const socket = io('http://localhost:4000');

class TopNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switch:"",
            switch2:"",
            menu:0,
            details:"",
            go:0,
            notice:false,
            data:"",
            error:false
            // endpoint: "http://localhost:4000",
        }
    }
    SocketLogin=()=>{
        // socket.emit('Login',{id:this.state.details.id,username:this.state.details.name})
    }
    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({details:res.data,go:1,});
           this.SocketLogin()
           
           });
        }
    }
    
    LoadData=()=>{
            Axios.get(`${Home}Notice`,{
            })
           .then(res => {
            //  console.log(res);
           this.setState({data:res.data});
           if (res.data.status === 'ON') {
               this.ChangeNotice(true);
           }else{
            this.ChangeNotice(false);
           }
           })
        .catch(err =>console.log(err));
    }

    Refresh=()=>{
        let token = "";
        if (localStorage.getItem('userToken') && this.state.go !== 0) {
            token = JSON.parse(localStorage.getItem('userToken'));
            Axios.post(`${Home}auth/checknoti`,{
                token: token
            })
           .then(res => {
            this.setState({error:false})
               //console.log(res)
            if (res.data.success) {
                this.buttonClick(res.data.message);
            }
           })
        .catch(err =>{
            this.setState({error:true})
            console.log(err)
        });
        }
    }

    buttonClick = (message) => {
        addNotification({
            title: 'New Message',
            subtitle: 'Check your chat',
            message: message,
            theme: 'darkblue',
            native: true, // when using native, your OS will handle theming.
            vibrate:1,
            silent:false
        });
        this.playAudio();
    }

    playAudio() {
        const audioEl = document.getElementsByClassName("audio-element")[0]
        audioEl.play()
      }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    componentDidMount(){
        this.LoadData();
        this.LoadEditData()
        this.interval = setInterval(() => this.Refresh(), 2000);
        // socket.on("connection", data => {
        //     this.setState({response: data})
        //     console.log('Socet',data)
        // });
        // socket.on('details',data=>
        // console.log('Connection',data)
        // // socket.emit('Login',{id:22,name:'Stephen'})
        // );
          
        }

    Switcher=(name)=>{
        this.setState({switch:name})
    }

    Switcher2=(name)=>{
        this.setState({switch2:name})
    }

    closeNav=(menu)=>{
        this.setState({menu:menu})
      }

      ChangeNotice=(bool)=>{
          this.setState({notice:bool})
      }

      Toggler=()=>{
          this.props.dispatch(toggle(true))
      }
    
    render() {
        let mo = true;
        if (window.innerWidth <= 700) {
            mo = true;
        }else{
            mo = false;
        }
        return (
            <div>
                <div>
                <Online>
                    {/* <div className="alert alert-success shadow">Only shown when you're online</div> */}
                    </Online>
                <Offline>
                    {this.state.error?
                    <div className="alert alert-danger st-network bg-danger text-white shadow text-center">You're currently offline.. Check your Internet connection <MdWifi shake={true} fontSize="25px" color="#e5ff0f" /></div>
                :''}
                    
                    </Offline>
            </div>
                {this.state.notice ?
                <div className="alert text-center alert-warning alert-dismissible fade show st-hi p-4" role="alert">
                   <IosAlertOutline shake={true} fontSize="41px" color="red" /> {this.state.data.desc}
                     <button onClick={()=>this.ChangeNotice(false)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
            :
            ''
            }
                
               
                 <Notifications />
                <audio className="audio-element">
                <source src={sound}></source>
                </audio>
                <div id="myNav" className="st-overlay" style={{width:`${this.state.menu}%`}}>
                <Link id="t" to="#" className="st-closebtn" onClick={()=>this.closeNav(0)}>&times;</Link>
                <div className="st-overlay-content">
                  <Link id="t" to="/">Home</Link>
                  <Link id="t" to="/contact">Contact Us</Link>
                  <Link id="t" onClick={()=>this.Switcher('WHOCODED')} to="#">Our Rates</Link>
                  
                  <Link to="/dashboard/buy" className="btn btn-primary m-1">Buy from us</Link>
                  <Link to="/dashboard/sell" className="btn btn-success m-1">Sell to us</Link>
                  {localStorage.getItem('userToken') ?
                  <Link to="/logout" className="btn btn-danger m-1">Log Out</Link>
                :
                
                ''}
                  
                </div>
              </div>
                {this.state.switch2 === 'WHOCODED' ?
                <ChatContainer close={()=>this.Switcher2('')} />
            :
            ''
            }
                
                {/* <button onClick={()=>this.Switcher2('WHOCODED')} className="btn btn-icon2 btn-soft-primary back-to-top hhh" style={{transition:'0.5s'}}>
                <IosChatboxes fontSize="27px" color="#ffffff" />
                </button> */}
                {this.state.switch === 'WHOCODED' ?
             <RatingPopMarket close={()=>this.Switcher('')} />  
            :
            ''}
                <header id="topnav" className={'defaultscroll st-desktop sticky '+this.props.sticky}>
            <div className="container">
                <div>
                    {mo ? 
                    <a className="logo" href="/">
                     <img src={log} className="l-dark" height="39" alt=""/>
                     </a>
                :
                <a className="logo" href="/">
                        {this.props.sticky ?

                        <img src={log} className="l-dark" height="39" alt=""/>
                    :
                    <img src={log2} className="l-light" height="39" alt=""/>
                    }
                        
                    </a>
                }
                    
                </div>      
                {localStorage.getItem('userToken') ? 
                <div className="buy-button">
                   
                <a  href="/logout" style={{marginLeft:'4px',marginRight:'4px'}}>
                    <div className="btn btn-danger login-btn-primary">Log Out</div>
                    <div className="btn btn-danger login-btn-light">Log Out</div>
                </a>
            </div> 
            :''}
                          
                <div className="buy-button">
                    <Link to="/dashboard/buy">
                        <div className="btn btn-primary login-btn-primary">Buy from us</div>
                        <div className="btn btn-success login-btn-light">Buy from us</div>
                    </Link>
                </div>
                <div className="buy-button">
                   
                    <Link onClick={()=>this.Toggler()} to="#" style={{marginLeft:'4px',marginRight:'4px'}}>
                        <div className="btn btn-dark login-btn-primary">Sell to us</div>
                        <div className="btn btn-secondary login-btn-light">Sell to us</div>
                    </Link>
                </div>
                
                <div className="menu-extras">
                    <div className="menu-item">
                        <Link onClick={()=>this.closeNav(100)} to="#" className="navbar-toggle">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
        
                <div id="navigation"> 
                    <ul className="navigation-menu nav-light">
                        <li>
                            <Link to="/">Home</Link>
                         </li>
                        <li>
                        <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                              <Link onClick={()=>this.Switcher('WHOCODED')} to="#">Our Rates</Link>
                            </li> 
                    </ul>
                    
                </div>
            </div>
        </header>
            </div>
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps)  (TopNav);