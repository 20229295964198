const initialState = {
    details: [],
    isFetching: false,
    error:false,
    errorCode:"",
    chats:[],
    fetching:false,
    children:"",
    menu:"dashboard",
    switch:"Home",
    login_layout:"",
    userDetails:"",
    isToast:false,
    ToastMessage:"",
    ToastToggle:false,
    params:[],
    expandStudents:false,
    searchResult:[],
    selectedStudent:[],
    examColumn:[],
    numberV:false,
    login:false,
    chat_id:0,
    toggle:false
    

};

const asyncReducer = (state = initialState, action) => {


    if (action.type === "MENU") {
        return Object.assign({}, state, {
            children: action.data,
        });
    } else if (action.type === "NumberV") {
        return Object.assign({}, state, {
            numberV: !state.numberV
        });
    } else if (action.type === "SWITCH") {
        return Object.assign({}, state, {
            switch: action.data
        });
    } else if (action.type === "LOGINR") {
        return Object.assign({}, state, {
            login: !state.login
        });
    } else if (action.type === "CHAT_ID") {
        return Object.assign({}, state, {
            chat_id: action.data
        });
    } else if (action.type === "USER_DETAILS") {
        return Object.assign({}, state, {
            userDetails: action.data
        });
    } else if (action.type === "TOAST") {
        return Object.assign({}, state, {
            ToastMessage: action.data,
        });
    } else if (action.type === "TOAST_TRIGGER") {
        return Object.assign({}, state, {
            isToast: action.data,
            ToastToggle:!state.ToastToggle
        });
    } else if (action.type === "PROPS_PARAMS") {
        return Object.assign({}, state, {
            params: action.data,
        });
    } else if (action.type === "EXPAND_STUDENT") {
        return Object.assign({}, state, {
            expandStudents: action.data
        });
    } else if (action.type === "SEARCH_STUDENT") {
        return Object.assign({}, state, {
            searchResult: action.data
        });
    } else if (action.type === "SELECT_STUDENT") {
        return Object.assign({}, state, {
            selectedStudent:action.data
        });
    } else if (action.type === "ADD_COLUMN") {
        return Object.assign({}, state, {
            examColumn: action.data
        });
    } else if (action.type === "REMOVE_COLUMN") {
        return Object.assign({}, state, {
            examColumn:action.data
        });
    } else if (action.type === "TOGGLE") {
        return Object.assign({}, state, {
            toggle:!state.toggle
        });
    }
    return state;


};

export default asyncReducer;