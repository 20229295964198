export const receive_post = post => {
    return {
        type: "CHAT_ID",
        data: post
    };
};


export const chat_id = id => {

    return function(dispatch, getState) {
        return dispatch(receive_post(id))
    };
};