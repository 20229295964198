import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import user from '../img/user.png'


class Testimonial extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        return (
            <div>
                <Carousel 
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                >
                <div class="card customer-testi border-0 text-center">
                                <div class="card-body">
                                    <img src={user} class="img-fluid avatar avatar-small rounded-circle mx-auto shadow ts" alt=""/>
                                    <p class="text-muted mt-4">"Coinwells is simply the best. Am yet to encounter any exchanger that delivers quality and fast service as you do. Keep up the good work guys!."</p>
                                    <h6 class="text-primary">- Chibuzor Amajioyi</h6>                                    
                                </div>
                            </div>
                

                <div class="card customer-testi border-0 text-center">
                                <div class="card-body">
                                    <img src={user} class="img-fluid avatar avatar-small rounded-circle mx-auto shadow ts" alt=""/>
                                    <p class="text-muted mt-4">" Excellent Customer care....provided funds in less than an hour.."  <h2></h2> <h2></h2><br></br> </p>
                                    <h6 class="text-primary">- Emmanuel Olamijuwon</h6>                                    
                                </div>
                            </div>

                <div class="card customer-testi border-0 text-center">
                                <div class="card-body">
                                    <img src={user} class="img-fluid avatar avatar-small rounded-circle mx-auto shadow ts" alt=""/>
                                    <p class="text-muted mt-4">" Good customer service. Fast delivery. Tested and trusted. Thanks.." <h2></h2> <h2></h2> <br></br> </p>
                                    <h6 class="text-primary">- Umeanozo ugochukwu</h6>                                    
                                </div>
                            </div>

                <div class="card customer-testi border-0 text-center">
                                <div class="card-body">
                                    <img src={user} class="img-fluid avatar avatar-small rounded-circle mx-auto shadow ts" alt=""/>
                                    <p class="text-muted mt-4">" Coinwells.com is indeed a very good and trustworthy platform to buy and sell your e-currencies. They are so reliable, I sell and buy my bitcoins here and there.."</p>
                                    <h6 class="text-primary">- Adedeji Mayowa</h6>                                    
                                </div>
                            </div>
                </Carousel>
                
            </div>
        );
    }
}

export default Testimonial;