import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/user.png';
import { chat_id } from '../store/actions/ChatId';
import IosRadioButtonOn from 'react-ionicons/lib/IosRadioButtonOn'


class ChatList extends Component {
    constructor(props) {
        super(props);
        this.state={
            style:"st-hover2",
            details:"",
            laoding:false,
            data:[1,2,3,4,5],
        }
    }
    
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    ChangeHandle=(name,id)=>{
        if (name == 'Chat') {
            this.props.dispatch(chat_id(id));
        }
        this.props.dispatch(switch_content(name))
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/listStaffChat`,{
               params:{ token: token}
            })
           .then(res => {
           this.setState({details:res.data,laoding:false});
           }).catch(err =>console.log(err));
        }
    }


    componentDidMount(){
        this.LoadEditData();
          this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        return (
            <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                <div className="table-responsive">
                <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>Our Customer Care Online  </h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={()=>this.ChangeHandle('Home',0)} className="btn btn-danger shadow pull-right">Return</button>
                                
                            </div>
                        </div>
                    </div>
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 this.state.details.length < 1 ?
                 <div className="alert alert-warning">No Staff online at the moment</div>
                 :
                 <>
                      <div className=" table-responsive mt-5">
                         
                      <table className="table mt-2 table-hover table-borderless table-striped">
                                <thead className="">
                                    <tr>
                                    <th style={{width:'6%'}}></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Last message</th>
                                    <th>Time</th>
                                    <th></th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.details.length < 1 ?
                                    ''
                                    :
                                    this.state.details.map(role=>
                                        <tr className="st-table-hover" onClick={()=>this.ChangeHandle('Chat',role.id)} key={role.id}>
                                            <td>
                                                <img src={role.picture} style={{width:'50px',height:'50px'}} className="rounded-circle st-avatar shadow" />
                                            </td>
                                        <td scope="row">
                                           {role.name}
                                        </td>
                                        <td>
                                            <strong><i>{role.role} </i></strong> {role.lastmessage}
                                        </td>
                                        <td>{role.time}</td>
                                        <td>
                                        <IosRadioButtonOn beat={true} fontSize="40px" color="#43853d" />
                                        </td>
                                        
                                        </tr>
                                    
                                    )}
                                    
                                </tbody>
                                </table>
                             
                            
                         </div>
                     
                      </>
        } 
                </div>
            </div>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (ChatList);