import React, { Component } from 'react';
import TopNav from '../components/navs/TopNav';
import { Link, Redirect } from 'react-router-dom';
import ParticleEffectButton from 'react-particle-effect-button'
import Axios from 'axios';
import { Home } from '../global/Home';
import { toast } from 'react-toastify';
import Toaster from '../global/Toaster';
import Spinner from '../components/loader/Spinner';
import { login_again } from '../store/actions/Rlogin';
import { switch_content } from '../store/actions/SwitchContent';
import { connect } from 'react-redux';
import AccountDetailsPop from '../components/AccountDetailsPop';
import BTCLIVE from '../components/BTCLIVE';

class Buy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            type:"0",
            amount:"",
            address:"",
            total:0,
            style:"st-hover2",
            totalBool:true,
            loading:false,
            number:"",
            details:"",
            switch:""
        }
    }

    Modal=(name)=>{
        this.setState({switch:name})
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>this.Relogin());
        }
    }

    LoadData=()=>{
        this.setState({loading:true});
            Axios.get(`${Home}rates2`,{
            })
           .then(res => {
            //  console.log(res);
           this.setState({loading:false,data:res.data});
           })
        .catch(err =>console.log(err));
    }

    changeStyle=(name)=>{
        this.setState({style:name})
    }

    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        }
        if (event.target.name === 'amount' || event.target.name === 'type') {
            let buy = this.state.data[this.state.type].sell;
            let total = event.target.value * buy;
            this.setState({total:total});
            if (event.target.value.length > 0) {
                this.setState({totalBool:false})
            }else{
                this.setState({totalBool:true})
            }
        }
      }

      handleSubmit=(event)=>{
        event.preventDefault();
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({loading:true});
            Axios.post(`${Home}auth/saveorder`,{
               token: token,
               amount:this.state.amount,
               phone:this.state.number,
               currency:this.state.data[this.state.type].id,
               type:1,
               address:this.state.address,
               total:this.state.total
            })
           .then(res => {
            //  console.log(res);
           this.setState({loading:false});
           if (res.data.success) {
            toast.success(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
                this.props.dispatch(switch_content('Orders'))
                this.interval = setTimeout(() => this.Modal('WHOCODED'), 1000);
           }else{
            toast.error(res.data.message,{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
           }
           })
        .catch(err =>this.Relogin());
        }
    }

    Relogin=()=>{
        this.props.dispatch(login_again(true));
    }

    componentDidMount(){
        this.LoadData();
        this.LoadEditData()
        this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
        
      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({style:''});
      }
    render() {
        if (!localStorage.getItem('userToken')) {
            return <Redirect to="/login" />
        }
        return (
            <div>
                <Toaster />
                {this.state.switch === 'WHOCODED'?
                   <AccountDetailsPop 
                    close={()=>this.Modal('')}
                   />  
                :''}
                <div className="bg-dark2" style={{height:'100vh'}}>
                <TopNav sticky={this.state.sroller? 'nav-sticky':''} />
                <section className="bg-half-170 bg-dark2 d-table w-100 bgg2"  >
                <div class="container">
                    <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                    <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>Buy from us</h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <Link to="/dashboard" className="btn btn-danger shadow pull-right">Return</Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                     
                      
                    
                    <form onSubmit={this.handleSubmit} className="rounded p-4 mt-0 bg-white shadow-lg">
                        
                               <div className="row text-left">
                                   <div className="col-md-3">
                                       <div className="form-group mb-0">
                                           <input onChange={this.handleChange} 
                                           value={this.state.amount}
                                           name="amount" 
                                           type="text" 
                                           className="form-control" 
                                           required="" 
                                           placeholder="How much do you need in dollars"/>
                                       </div>
                                   </div>
                                   
                                   <div className="col-md-9">
                                       <div className="row align-items-center">
                                           <div className="col-md-3 mt-4 mt-sm-0">
                                               <div className="form-group mb-0">
                                                   <select name="type" value={this.state.type} onChange={this.handleChange} className="form-control custom-select">
                                                       <option value="">Select Currency</option>
                                                       {this.state.data.length > 0 ?
                                                      this.state.data.map((da,i)=>
                                                      <option key={da.id} value={i}>{da.title}</option>
                                                        ) 
                                                    :''}
                                                       
                                                   </select>
                                               </div>
                                           </div>
                                           
                                           <div className="col-md-5 mt-4 mt-sm-0">
                                               <div className="form-group mb-0">
                                               <input 
                                               name="address"
                                               value={this.state.address}
                                               type="text" 
                                               onChange={this.handleChange}
                                               className="form-control" required
                                                placeholder="Address"/>
                                               </div>
                                           </div>
   

                                           <div className="col-md-4 mt-4 mt-sm-0">
                                               {this.state.loading ?
                                               <Spinner />
                                            :
                                            this.state.amount > 50 && !this.state.details.div2 ?
                                            <button onClick={alert('You have to upgrade your account to buy up to $50. Go yo your profile and upgrade your account.')} type="button" name="search" className="searchbtn btn btn-primary btn-block p"> Buy Now </button>
                                            :
                                            this.state.amount < 19 ?
                                            <button type="button" id="search" name="search" className="searchbtn btn btn-dark btn-block p disabled"> Buy Now (min $20) </button>
                                            :
                                            <button id="search" name="search" className="searchbtn btn btn-primary btn-block p"> Buy Now </button>
                                            }
                                               
                                           </div>
                                       </div>
                                   </div> 
                                   
                               </div>
                               <center>
                                   <div className={this.state.totalBool?'st-abss':'d-block'}>
                                   <ParticleEffectButton
                                        duration={1000}
                                            color='#35357D'
                                            hidden={this.state.totalBool}
                                        >
                                      <div className="alert alert-primary alert-pills mt-5 shodow-lg" role="alert">
                                        <span className="badge badge-pill badge-danger"> Total Amount </span>
                                            <span className="alert-content"> ₦{this.state.total}.00 </span>
                                            </div>
                                </ParticleEffectButton>
                                   </div>
                               
                               
                               </center>
                           </form>
                    
                    
                       

                        <BTCLIVE />
                       </div>


                    </div>
            </div>
                </section>
            </div>
            </div>
        );
    }
}

const mapStoreToProps = (store) =>{
    return{
       data:store
    }
  }

export default connect(mapStoreToProps) (Buy);