import React, { Component } from 'react';
import Axios from 'axios';
import { Home } from '../global/Home';

class BTCLIVE extends Component {
    constructor(props) {
        super(props);
        this.state ={
            details:"",
            laoding:false
        }
    }

    LoadEditData=()=>{
        this.setState({laoding:true})
        Axios.get(`${Home}btc`,{
          })
         .then(res => {
           //console.log(res)
         this.setState({details:res.data,laoding:false});
         
         })
    }

    componentDidMount(){
        this.LoadEditData();
        }
    
    render() {
        return (
            <div className="row mt-5">
                            <div className="col-md-1">
                                <div className="pull-right">
                                {/* <LogoBitcoin beat={true} onClick={() => alert('Hi!')} fontSize="60px" color="#43853d" /> */}
                                </div>
                            </div>
                            <div className="col-md-10">
                                <center>
                                <div className="alert alert-outline-primary alert-pills shadow-lg" role="alert">
                                <span className="badge badge-pill badge-danger"> Bitcoin - USD </span>
                                {this.state.laoding ?
                                ' Loading....'
                                :
                                <span className="alert-content"> <strong>Latest</strong> :{this.state.details.last} | <strong>Highest</strong> : {this.state.details.high} <strong>Bid</strong> :{this.state.details.bid} </span>
                                }
                               
                                </div>
                                </center>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
        );
    }
}

export default BTCLIVE;