export const receive_post = post => {
    return {
        type: "LOGINR",
        data: post
    };
};


export const login_again = bool => {

    return function(dispatch, getState) {
        return dispatch(receive_post(bool))
    };
};