import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/user.png'
import { login_again } from '../store/actions/Rlogin';
import UpgradeAccount from './UpgradeAccount';
import ImgsViewer from 'react-images-viewer'

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state={
            style:"st-hover2",
            details:"",
            laoding:false,
            modal:"",
            view:false,
            pix:[]
        }
    }
    
    changeStyle=(name)=>{
        this.setState({style:name})
    }

    ChangeHandle=(name)=>{
        this.props.dispatch(switch_content(name))
       // this.setState({current:name})
    }

    ChangeModal=(name)=>{
        this.setState({modal:name});
      }
    Relogin=()=>{
        this.props.dispatch(login_again(true));
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>this.Relogin());
        }
    }

    componentDidMount(){
        this.LoadEditData();
          this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }

        gotoNextImg =()=>{
            // this.setState({index:1})
         }
     
         gotoPrevImg=()=>{
             
         }
         closeImgsViewer=()=>{
            this.setState({view:false});
            // this.props.dispatch(view_image(false));
            // this.props.dispatch(current_image([]));
        }

        ViewImageHandler=(imageArray)=>{
            
            const IMG_SET = [
                {
                  src: imageArray,
                  srcSet: imageArray,
                }
              ]
    
            this.setState({pix:IMG_SET,view:!this.state.view})
        }
    
    render() {
        
        return (
            <>
            {this.state.modal === 'WHOCODED' ?
              <UpgradeAccount 
              close={()=>this.ChangeModal('')}
               />
            :''}
            {this.state.view ?
            <ImgsViewer
                   showImgCount={false}
                   backdropCloseable={true}
                    imgs={this.state.pix}
                    currImg={0}
                    isOpen={this.state.view}
                    onClickPrev={this.gotoPrevImg}
                    onClickNext={this.gotoNextImg}
                    onClose={this.closeImgsViewer}
                />
        :''}
            
            <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
                <div className="table-responsive">
                <div className="row">
                        <div className="col-md-6 st-half">
                            <h4>Your Profile</h4>
                        </div>
                        <div className="col-md-6 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                
                                <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow pull-right">Return</button>
                                <button onClick={()=>this.ChangeHandle('Edit_Profile')} className="btn btn-primary shadow pull-right" style={{marginRight:'5px'}}>Edit Profile</button>
                                {this.state.details.div2 ?
                                <button disabled={true} className="btn btn-success shadow pull-right" style={{marginRight:'5px'}}> ✅ Account Upgaded</button>
                            :
                            <button onClick={()=>this.ChangeModal('WHOCODED')} className="btn btn-success shadow pull-right" style={{marginRight:'5px'}}>Upgrade account</button>
                            }
                                
                            </div>
                        </div>
                    </div>
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 <>
                 
                    <center>
                        <div onClick={()=>this.ViewImageHandler(pix)} className="avatar avatar-xxl">
                            <img className="rounded-circle shadow-lg sty" src={this.state.details.picture} />
                         </div>
                        </center>
                      <div className=" table-responsive mt-5">
                          <table className="table mt-2 table-hover table-bordered">
                              <tbody>
                                  <tr>
                                      <th>First Name</th>
                                      <td>{this.state.details.firstName}</td>
                                  </tr>
                                  <tr>
                                      <th>Last Name</th>
                                      <td>{this.state.details.lastName}</td>
                                  </tr>
                                  <tr>
                                      <th>Phone Number</th>
                                      <td>{this.state.details.phone}</td>
                                  </tr>
                                  <tr>
                                      <th>City</th>
                                      <td>{this.state.details.city}</td>
                                  </tr>
                                  <tr>
                                      <th>State</th>
                                      <td>{this.state.details.state}</td>
                                  </tr>
                                  <tr>
                                      <th>Country</th>
                                      <td>{this.state.details.country}</td>
                                  </tr>
                                  
                                  <tr>
                                      <th>Address</th>
                                      <td>{this.state.details.address}</td>
                                  </tr>
                                  
                              </tbody>
                          </table>
                      </div>
                      </>
        } 
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (Profile);