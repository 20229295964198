export const receive_post = post => {
    return {
        type: "TOGGLE",
        data: post
    };
};


export const toggle = bool => {

    return function(dispatch, getState) {
        return dispatch(receive_post(bool))
    };
};