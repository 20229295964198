import React, { Component } from 'react';
import Icon from 'antd/lib/icon';
import PropTypes from 'prop-types';
import ListSort from './ListSort';
import { connect } from 'react-redux';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/bit.png'
import rmb from '../img/rmb.png'
import pm from '../img/pm.png'
import { Home } from '../global/Home';
import Axios from 'axios';
import Spinner from './loader/Spinner';


const dataArray = [
  {
    icon: pix,
    color: '#FF5500',
    title: 'Bitcoin',
    text: 'Buy at ₦438. Sell at ₦427',
  },
  {
    icon: pm,
    color: '#5FC296',
    title: 'Perfect Money',
    text: 'Buy at ₦438. Sell at ₦427',
  },
  {
    icon: rmb,
    color: '#2DB7F5',
    title: 'RMB',
    text: 'Buy at ₦438. Sell at ₦427',
  }
];
class ListSortDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style:"st-hover2",
            loading:false,
            data:[]
        }
    }
    
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'list-sort-demo',
  };


  LoadData=()=>{
    this.setState({loading:true});
        Axios.get(`${Home}rates`,{
        })
       .then(res => {
        //  console.log(res);
       this.setState({loading:false,data:res.data});
       })
    .catch(err =>console.log(err));
}

  ChangeHandle=(name)=>{
    this.props.dispatch(switch_content(name))
   // this.setState({current:name})
   }

   changeStyle=(name)=>{
    this.setState({style:name})
}

   componentDidMount(){
     this.LoadData();
      this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
      
    }
  
    componentWillUnmount() {
      clearInterval(this.interval);
      this.setState({style:''});
    }

  render() {
    console.log(this.state);
    const childrenToRender = this.state.data.map((item, i) => {
      const {
        icon, color, title, text,
      } = item;
      return (
        <div key={i} className={`${this.props.className}-list`}>
          <div className={`${this.props.className}-icon`}>
            <Icon type={icon} style={{ color }} />
            <img src={icon} className="rounded-circle avatar-small sty2" />
          </div>
          <div className={`${this.props.className}-text`}>
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
        </div>
      );
    });
    return (
        <div className={`card bg-light shdow-lg card-body ${this.state.style}`}>
            {this.props.nohead? 
        ''    
        :
        
             <div className="row">
                        <div className="col-md-8 st-half">
                            <h4>Live Rates</h4>
                        </div>
                        <div className="col-md-4 st-half">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow pull-right">Return</button>
                            </div>
                        </div>
                    </div>
  }
         <div className={`${this.props.className}-wrapper`}>
        <div className={this.props.className}>
          {this.state.loading ?
          <Spinner />
        :
        
          <ListSort
            dragClassName="list-drag-selected"
            appearAnim={{ animConfig: { marginTop: [5, 30], opacity: [1, 0] } }}
          >
            {childrenToRender}
          </ListSort>
     }
        </div>
      </div>
        </div>
      
    );
  }
}

const mapStateToProps = store =>({
    data:store
});


export default connect(mapStateToProps) (ListSortDemo);