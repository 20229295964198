import React, { Component } from 'react';
import MdSend from 'react-ionicons/lib/MdSend'
import IosCloseCircle from 'react-ionicons/lib/IosCloseCircle'


class ChatContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            style:""
        }
    }

    changeStyle=(name)=>{
        this.setState({style:name})
    }

    componentDidMount(){
        this.interval = setTimeout(() => this.changeStyle('open-chat2'), 5);
        
      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({style:''});
      }
    render() {
        return (
            <div>
                  <div className={`ui-block popup-chat popup-chat-responsive chat-min-h100 ${this.state.style} shadow-lg`} style={{zIndex:111111}}>
                      <div className="modal-content">
                          <div className="modal-header">
                              <h6 class="title">Edward Stev</h6>
                              <div className="more">
                                 <IosCloseCircle onClick={this.props.close} fontSize="30px" color="#ffffff" />
                                 
                              </div>
                          </div>
                          <div className="modal-body">
                              <div className="mCustomScrollbar chat-min-h st-scroll ps--theme_default ps--active-y">
                                  <ul className="notification-list chat-message chat-message-field-st p-0">
                                      <li>
                                          <div className="author-thumb pull-right">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_img8e639933b21a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-right pr">
                                            <span class="chat-message-item">This is a sanpme 😦 and theree's no👨‍💻</span>
                                          </div>
                                      </li>

                                      <li>
                                          <div className="author-thumb pull-right">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_img8e639933b21a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-right pr">
                                            <span class="chat-message-item">This is a sanpme 😦 and theree's no👨‍💻</span>
                                          </div>
                                      </li>

                                      <li>
                                          <div className="author-thumb pull-left">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_imgfbc500cc300a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-left pl">
                                            <span class="chat-message-item">theree's no👨‍💻</span>
                                          </div>
                                      </li>
                                      <li>
                                          <div className="author-thumb pull-left">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_imgfbc500cc300a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-left pl">
                                            <span class="chat-message-item">theree's no👨‍💻</span>
                                          </div>
                                      </li>
                                      <li>
                                          <div className="author-thumb pull-left">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_imgfbc500cc300a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-left pl">
                                            <span class="chat-message-item">theree's no👨‍💻</span>
                                          </div>
                                      </li>
                                      <li>
                                          <div className="author-thumb pull-right">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_img8e639933b21a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-right pr">
                                            <span class="chat-message-item">This is a sanpme 😦 and theree's no👨‍💻</span>
                                          </div>
                                      </li>
                                      <li>
                                          <div className="author-thumb pull-right">
                                           <img alt="whocoded" src="https://eventplux.eventplux.com/uploads/profilepix/Whocoded_img8e639933b21a.jpg" class="pro-avatar" />
                                          </div>
                                          <div className="pull-right pr">
                                            <span class="chat-message-item">This is a sanpme 😦 and theree's no👨‍💻</span>
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                              <form className="need-validation">
                                  <div className="form-group is-empty frs">
                                   <textarea cols={1} rows={2} name="message" class="form-control" placeholder="Press enter to post..."></textarea>
                                    <div className="add-options-message">
                                        <a href="#" className="options-message">
                                        <MdSend fontSize="30px" color="#000" />
                                        </a>
                                    </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
            </div>
        );
    }
}

export default ChatContainer;