import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Home } from '../global/Home';
import Spinner from '../components/loader/Spinner';
import { switch_content } from '../store/actions/SwitchContent';
import pix from '../img/coinwell.png'
import Toaster from '../global/Toaster';
import { toast } from 'react-toastify';
import MdSend from 'react-ionicons/lib/MdSend'
import IosCloseCircle from 'react-ionicons/lib/IosCloseCircle'
import { Link } from 'react-router-dom';
import { login_again } from '../store/actions/Rlogin';
import ImageUploading from "react-images-uploading";
import MaterialIcon from 'material-icons-react';

const maxNumber = 1;

class Ticket extends Component {
    constructor(props) {
        super(props);
        this.state={
            style:"st-hover2",
            details:"",
            laoding:false,
            laoding2:false,
            name:"",
            number:"",
            message:"",
            data:"",
            search:"",
            picture2:[],
            upload:0,
            uploading:false,
            nextPage:[],
            isPaginating:false
        }
    }

    trackScrolling = () => {
        //console.log(window.pageYOffset);
        let token = "";
        let nextPage = this.state.nextPage;
        if (nextPage !==null) {
         if (!this.state.isPaginating) {
             this.setState({isPaginating:true});
             if (localStorage.getItem('userToken')) {
                 token = JSON.parse(localStorage.getItem('userToken'));
                 Axios.get(nextPage,{
                     params:{token: token}
                 })
                 .then(res => {
                 
                 if (res.data.length > 0) {
                     for (let index = 0; index < res.data.length; index++) {
                         this.state.details.unshift(res.data[index]);
                     }
                     this.setState({nextPage:res.data[0].nextPageUrl, details: this.state.details});
                 }else{
                     this.setState({nextPage:null});
                 }
                 
                 this.setState({isPaginating:false,});
                 })
             }
             
            }
        }
  
    }
    onChangeImage = imageList => {
        this.setState({picture2:imageList})
        // console.log(this.state.picture2);
      }
    
    changeStyle=(name)=>{
        this.setState({style:name})
    }
    handleChange = (event)=>{
        if (event.target.type !== 'files') {
          this.setState({[event.target.name]: event.target.value});
        } 
      }

    ChangeHandle=(name)=>{
        this.props.dispatch(switch_content(name))
       // this.setState({current:name})
    }

    LoadEditData=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/user`,{
              params:{ token: token}
            })
           .then(res => {
            // console.log(res)
           this.setState({details:res.data,laoding:false});
           
           })
        .catch(err =>this.Relogin());
        }
    }

    

    handleSubmit2=(event)=>{
        if (event) {
            event.preventDefault(); 
        }
        
        let token = "";
        if (this.state.message.length > 0) {
            if (localStorage.getItem('userToken')) {
                token = JSON.parse(localStorage.getItem('userToken'));
                this.setState({laoding2:true});
                Axios.post(`${Home}auth/postChat`,{
                  token: token,
                  message:this.state.message,
                  id:this.props.data.chat_id
                })
               .then(res => {
                // console.log(res)
               this.setState({laoding2:false,message:"",details:this.state.details.concat(res.data.data)});
               
               })
            .catch(err =>this.Relogin());
            }
        }else{
            toast.error("Can't send empty message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
        } 
    }

    handleSubmit3=(event)=>{
        if (event) {
            event.preventDefault(); 
        }
        let token = "";
        if (this.state.search.length > -1) {
            if (localStorage.getItem('userToken')) {
                token = JSON.parse(localStorage.getItem('userToken'));
                this.setState({laoding2:true});
                Axios.post(`${Home}auth/searchChat`,{
                  token: token,
                  search:this.state.search,
                  id:this.props.data.chat_id
                })
               .then(res => {
                // console.log(res)
               this.setState({laoding2:false,message:"",details:res.data});
               
               })
            .catch(err =>this.Relogin());
            }
        }else{
            toast.error("Can't send empty message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
        } 
    }

    Upload=()=>{
        
        let token = "";
        if (this.state.picture2.length > 0) {
            if (localStorage.getItem('userToken')) {
                token = JSON.parse(localStorage.getItem('userToken'));
                this.setState({uploading:true});
                Axios.post(`${Home}auth/uploadImageChat`,{
                  token: token,
                  picture:this.state.picture2,
                  id:this.props.data.chat_id
                },{
                    onUploadProgress: ProgressEvent =>{
                        let MyProgress = Math.round(ProgressEvent.loaded/ProgressEvent.total *100);
                
                       // console.log('Progress: '+ Math.round(ProgressEvent.loaded/ProgressEvent.total *100)+" %");
                        if(MyProgress < 99){
                            this.setState({upload:MyProgress})
                        }else{
                            this.setState({upload:0})
                        }
                        
                      }
                })
               .then(res => {
                // console.log(res)
               this.setState({uploading:false,
                message:"",
                details:this.state.details.concat(res.data.data),
                picture2:[]
            });
               
               })
            .catch(err =>this.Relogin());
            }
        }else{
            toast.error("Can't send empty message",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
                });
        } 
    }

    Relogin=()=>{
        this.props.dispatch(login_again(true));
    }


    LoadChats=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding:true});
            Axios.get(`${Home}auth/mychats/${this.props.data.chat_id}`,{
              params:{token: token}
            })
           .then(res => {
            //console.log(res)
           this.setState({details:res.data,laoding:false});
           if(res.data.length > 0){
            this.setState({nextPage:res.data[0].nextPageUrl})
            }else{
                this.setState({nextPage:null})
            }
           
           })
        .catch(err =>this.Relogin());
        }
    }

    getUsername=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            this.setState({laoding2:true});
            Axios.post(`${Home}auth/getSingleUser`,{
               token: token,
               id:this.props.data.chat_id
            })
           .then(res => {
            //console.log(res)
           this.setState({data:res.data,laoding2:false});
           
           })
        .catch(err =>this.Relogin());
        }
    }

    Refresh=()=>{
        let token = "";
        if (localStorage.getItem('userToken')) {
            token = JSON.parse(localStorage.getItem('userToken'));
            Axios.get(`${Home}auth/mychatsRefresh/${this.props.data.chat_id}`,{
             params:{token: token}
            })
           .then(res => {
               //console.log(res.data)
               if (res.data.length > 0) {
                this.setState({details:this.state.details.concat(res.data)});
               }
               
           })
        .catch(err =>this.Relogin());
        }
    }


    componentDidMount(){
        this.LoadChats();
        this.getUsername()
          this.interval = setTimeout(() => this.changeStyle('st-hover1'), 5);
          this.interval = setInterval(() => this.Refresh(), 2000);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
        handleKey=(event)=>{
            if (event.which == 13 && !event.shiftKey) {
                this.handleSubmit2()
            }
            //console.log(event.which);
        }
    render() {
        // console.log(this.state);
        return (
            <>
            <Toaster />
            <div className={` ${this.state.style}`}>
                <div className="table-responsivennn">
                {/* <div className="row">
                        <div className="col-md-8">
                            <h4>Submit a ticket</h4>
                        </div>
                        <div className="col-md-4">
                            <div id="st" style={{marginRight:'10px'}}>
                                <button onClick={()=>this.ChangeHandle('Home')} className="btn btn-danger shadow pull-right">Return</button>
                            </div>
                        </div>
                    </div> */}
                    {this.state.laoding ?
                    <div>
                        <Spinner />
                    </div>
                 :
                 <>
                 
                   
                      <div className=" table-responsive mt-5 mb-5">
                         <div className="row">
                             
                                
                                 <div className="col-md-12">
                                     <div>
                                     
                                     <div className="card rounded shadow border-0 ml-lg-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1400">






                                     <form onSubmit={this.handleSubmit3} class="form-inline" style={{background:'#041a30'}}>
                                <div class="form-group mb-2">
                                    <input name="search" value={this.state.search} onChange={this.handleChange} type="text" className="form-control form-control-sm" />
                                </div>
                                <button type="submit" class="btn btn-primary mb-2">Search</button>
                                </form>
                                     <div className={`ui-blo popup-chat mb-0 ${this.state.style} shadow-lg`} style={{zIndex:111111,}}>
                      <div className="modal-content">
                          <div className="modal-header">
                              <h6 class="title">{this.state.data.name}</h6>
                              <div className="more">
                              
                                 <IosCloseCircle onClick={()=>this.ChangeHandle('Ticket')} fontSize="30px" color="#ffffff" />
                              </div>
                          </div>
                          <div className="modal-body">
                              <div className="mCustomScrollbar chat-min-h st-scroll ps--theme_default ps--active-y">
                                  {this.state.details.length > 0 ?
                                  <ul className="notification-list chat-message chat-message-field-st p-0">
                                      {this.state.nextPage !== null ?
                                      <center>
                                          {this.state.isPaginating ? 
                                          'Loading More...'
                                        :
                                        <button onClick={this.trackScrolling} className="btn btn-sm  btn-outline-primary">Load more</button>
                                        }
                                        
                                      </center>
                                      
                                    :''}
                                  {this.state.details.map(chat=>
                                    
                                    chat.myChat ?
                                    <li key={chat.id}>
                                    <div className="author-thumb pull-right">
                                     <img alt="whocoded" src={chat.pix} class="pro-avatar" />
                                    </div>
                                    <div className="pull-right pr">
                                    {chat.image === 1 ?
                                            <img src={chat.message} className="img-fluid img-thumbnail shadow" style={{width:'200px',height:'200px'}} />
                                        :
                                        <span className="chat-message-item">
                                            {chat.message}
                                        </span>
                                        }
                                      
                                    </div>
                                </li>
                                    :

                                    <li key={chat.id}>
                                      <div className="author-thumb pull-left">
                                       <img alt="whocoded" src={chat.pix} class="pro-avatar" />
                                      </div>
                                      <div className="pull-left pl">
                                      {chat.image === 1 ?
                                            <img src={chat.message} className="img-fluid img-thumbnail shadow" style={{width:'200px',height:'200px'}} />
                                        :
                                        <span className="chat-message-item">
                                            {chat.message}
                                        </span>
                                        }
                                        
                                      </div>
                                  </li>
                                    
                                    )}
                                  

                                  
                              </ul>
                              :''
                                }
                                  
                              </div>
                              <form id="myForm" onSubmit={this.handleSubmit2} className="need-validation">
                                  <div className="form-group is-empty frs">
                                   <textarea 
                                   onKeyPress={this.handleKey}
                                   cols={1}
                                   onChange={this.handleChange} rows={2} name="message" value={this.state.message}
                                    class="form-control"
                                     placeholder="Enter a message"></textarea>
                                    <div className="add-options-message">
                                        {this.state.laoding2 ?
                                        'Sending..'
                                    :
                                    <Link to="#" onClick={this.handleSubmit2} className="options-message">
                                        <MdSend fontSize="30px" color="#000" />
                                        </Link>
                                    }
                                        
                                    </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                      {this.state.upload > 0 ?
                            
                            <div className="progress-box">
                            <div className="progress">
                                <div className="progress-bar position-relative bg-primary" style={{width:`${this.state.upload}%`}}>
                                </div>
                            </div>
                            </div>
                        :''}

                                            <div className="form-group mb-2" id="st-up">
                                            <ImageUploading onChange={this.onChangeImage} maxNumber={maxNumber} multiple>
                                            {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                            // write your building UI
                                            <div>
                                            {this.state.picture2.length < 1 ?
                                            <>
                                            <button type="button" className="btn btn-primary shadow d-flex m-1" onClick={onImageUpload}>
                                              <MaterialIcon color="#ffffff" icon="add_a_photo" />
                                            </button>
                                               
                                            </>
                                            :''
                                            }
                                                
                                            <div className="row">
                                                {imageList.map(image => (
                                                <div className="col-md-12 p-2" key={image.key}>
                                                <button type="button" className="st-btn btn-danger shadow-sm m-2" onClick={image.onUpdate}>Change</button>
                                                    <div className="avatar avatar-xxl m-2">
                                                    <img className="rounded-circl shadow-lg sty" src={image.dataURL} />
                                                    </div>
                                                    <div>
                                                
                                                    </div>
                                                    {this.state.uploading ? 
                                                    <button disabled={true} className="btn btn-secondary disabled shadow m-2">Uploading... Please wait</button>
                                                :
                                                <button onClick={this.Upload} className="btn btn-primary shadow m-2"> Send Image</button>
                                                }
                                                
                                                </div>
                                                
                                                ))}
                                            </div>
                                            </div>
                                            )}
                                    </ImageUploading>
                                    </div>
                                
                  </div>






                        </div>

                                     </div>
                                 </div>
                                
                             </div>
                             
                             
                            
                         </div>
                     
                      </>
        } 
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = store =>({
    data:store
});

export default connect(mapStateToProps) (Ticket);