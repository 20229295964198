import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom'
import validator from 'validator';
import axios from 'axios';
import {Home} from '../global/Home';
import { toast } from 'react-toastify';
import Toaster from '../global/Toaster';
import Spinner from '../components/loader/Spinner';
import ResetPass from '../components/ResetPass';
import logo from '../img/coinwell.png'
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:"",
      emailError:"",
      passwordError:"",
      loading:false,
      phone:"",
      modal:""
    }
  }

  handleChange = (event)=>{
    if (event.target.type !== 'files') {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  ChangeModal=(name)=>{
    this.setState({modal:name});
  }

  Validator =()=>{
    if (!validator.isNumeric(this.state.phone)) {
      this.setState({emailError:"Valid number is required"})
    }else{
      this.setState({emailError:""})
    }

    if (this.state.password.length < 1) {
      this.setState({passwordError:"Password required"})
    }else{
      this.setState({passwordError:""})
    }

    if (!validator.isNumeric(this.state.phone) || this.state.password.length < 1) {
      return false;
    }else{
      return  true;
    }
  }

  handleSubmit =(event)=>{
    event.preventDefault();
    let valid = this.Validator();
    if (valid) {
      this.setState({loading:true})
        axios.post(Home+`auth/login`, 
              { 
                type:2,
                phone: this.state.phone,
                password: this.state.password,
                web:1
               })
               .then(res =>{
                // console.log(res);
               this.setState({loading:false})
               if(res.data.token){
                
                localStorage.setItem("userToken", JSON.stringify(res.data.token));
                this.setState({logged:true,message:'',done:true});
                this.props.history.push('/dashboard');
               }else{
                  toast.error(res.data.message,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false
                    });
               }
              })
    }
  }
  
    render() {
      let classs = true;
      if (window.innerWidth <= 700) {
        classs = true;
      }else{
        classs = false;
      }
      // if (localStorage.getItem('userToken')) {
      //   return <Redirect to="/dashboard" />
      // }
        return (
            <div>
              {this.state.modal === 'WHOCODED' ?
              <ResetPass 
              close={()=>this.ChangeModal('')}
               />
            :''}
              <Toaster />
                <div className="back-to-home rounded d-none d-sm-block shadow">
            <Link to="/" className="text-white title-dark rounded d-inline-block text-center">
            <i className="fa fa-home"></i>
            </Link>
        </div>
        <section className="cover-user bg-white">
          <div className="container-fluid px-0">
            <div className="row no-gutters position-relative">
              <div className="col-lg-5 cover-my-30 order-2">
                <div className="cover-user-img d-flex align-items-center">
                  <div className="row">
                    <div className="col-12">
                      <div className="card login-page border-0">
                        <div className="card-body p-mo">
                          <h4 className="card-title text-center">Login</h4>
                          <form className="login-form mt-4" onSubmit={this.handleSubmit}>
                           
                            <div className="row">
                                <div className="col-lg-12 mt-5">
                                    <div className="form-group position-relative">
                                        <label>Phone Number <span className="text-danger">*</span></label>
                                        <i className="fa fa-user icons"></i>
                                        <input 
                                        type="number" 
                                        className="form-control pl-5" 
                                        placeholder="Number" 
                                        name="phone" 
                                        autoFocus required
                                        value={this.state.phone}
                                        onChange={this.handleChange}
                                         />
                                         <small className="text-danger">{this.state.emailError}</small>
                                        </div>
                                   </div>

                                   <div className="col-lg-12">
                                    <div className="form-group position-relative">
                                        <label>Your Password <span className="text-danger">*</span></label>
                                        <i className="fa fa-user icons"></i>
                                        <input 
                                        type="password" 
                                        className="form-control pl-5" 
                                        placeholder="Password" 
                                        name="password" 
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        required />
                                        <small className="text-danger">{this.state.passwordError}</small>
                                        </div>
                                   </div>
                                   <div className="col-lg-12">
                                                    <div className="d-flex justify-content-between mb-2">
                                                            <p className="forgot-pass mb-0">
                                                              <Link onClick={()=>this.ChangeModal('WHOCODED')} to="#" className="text-dark font-weight-bold">Forgot password ?</Link>
                                                              </p>
                                                        </div>
                                                    </div>
                                   
                                                    <div className="col-lg-12 mb-0">
                                                      {this.state.loading?
                                                      <center>
                                                        <Spinner />
                                                      </center>
                                                      
                                                    :
                                                    <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                                                    }
                                                        
                                                    </div>
                                                    
                                

                                    <div className="col-12 text-center">
                                            <p className="mb-0 mt-3"><small className="text-dark mr-2">Don't have an account ?</small> <Link to="/register" className="text-dark font-weight-bold">Sign Up</Link></p>
                                        </div>
                            </div>
                 
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 offset-lg-5 padding-less img order-1 short" style={{backgroundImage:'url(https://picsum.photos/2000/1333)',filter:'brightness(0.3)'}}>
                <span>
                  {classs?
                  <div className="back-to-home bg-success rounded d-sm-block shadow">
                  <Link to="/" style={{background:'#ffffff'}} className=" text-dark title-dark rounded d-inline-block text-center">
                  <i className="fa fa-home"></i>
                  </Link>
              </div>
                :''}
                
                </span>
              </div>
            </div>
          </div>
        </section>
            </div>
        );
    }
}

export default Login;