import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom'
import validator from 'validator';
import { toast } from 'react-toastify';
import Toaster from '../global/Toaster';
import axios from 'axios';
import {Home} from '../global/Home';
import Spinner from '../components/loader/Spinner';
import Particles from 'react-particles-js';


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fistName:"",
      lastName:"",
      fError:"",
      lError:"",
      number:"",
      email:"",
      gender:"",
      emailError:"",
      numberError:"",
      passwordError:"",
      loading:false,
      country:"Nigeria",
      state:"",
      city:"",
      network:"",
      address:"",
      details:[],
      lg:[],
      password2:"",
      passwordError2:""
    }
  }

  handleChange = (event)=>{
      //console.log(event)
    if (event.target.type !== 'files') {
        if (event.target.name === 'state') {
          let newdata = this.state.details[event.target.value];
          this.setState({
              state:newdata.id,
              lg:newdata
          })
        }else{
            this.setState({[event.target.name]: event.target.value});
        }
      
      
    }
  }

  Validator =()=>{
   

    if (this.state.password.length < 4) {
      this.setState({passwordError:"Password required and must be atleast 4 character length"})
    }else{
      this.setState({passwordError:""})
    }

    if (this.state.password !== this.state.password2) {
      this.setState({passwordError2:"Password didnt match"})
    }else{
      this.setState({passwordError2:""})
    }

    if (!validator.isAlphanumeric(this.state.fistName) || this.state.fistName.length < 1) {
      this.setState({fError:"First name is not valid"})
    }else{
      this.setState({fError:""})
    }

    if (!validator.isAlphanumeric(this.state.lastName) || this.state.lastName.length < 1) {
      this.setState({lError:"Last name is not valid"})
    }else{
      this.setState({lError:""})
    }

    if (!validator.isNumeric(this.state.number)) {
      this.setState({numberError:"Number must a number"})
    }else if (this.state.number.length !== 11) {
      this.setState({numberError:"Number must be 11 character length"})
    } else {
      this.setState({numberError:""})
    }

    if (this.state.password.length < 4 || (this.state.password !== this.state.password2) || !validator.isAlphanumeric(this.state.fistName) || this.state.fistName.length < 1 ||
    !validator.isAlphanumeric(this.state.lastName) || this.state.lastName.length < 1 || !validator.isNumeric(this.state.number) || this.state.number.length !== 11) {
      return false;
    }else{
      return  true;
    }
  }

  handleSubmit =(event)=>{
    event.preventDefault();
    let valid = this.Validator();
    if (valid) {
      this.setState({loading:true})
        axios.post(Home+`auth/login`,
              { 
                type:1,
                network: this.state.network,
                password: this.state.password,
                firstName:this.state.fistName,
                lastName:this.state.lastName,
                country:this.state.country,
                state:this.state.state,
                address:this.state.address,
                username:this.state.username,
                phone:this.state.number,
                city:this.state.city,
               })
               .then(res => {

                // console.log(res);
               this.setState({loading:false})
               if(res.data.success){
                
                localStorage.setItem("userToken", JSON.stringify(res.data.token));
                this.setState({logged:true,message:'',done:true});
                this.props.history.push('/dashboard');
               }else{
                  toast.error(res.data.message,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false
                    });
               }
              }).catch(error=>
                this.HandleEorror(error)
                )
    }
  }

  LoadLocations=()=>{
    axios.get(Home+`locations`,
      )
     .then(res => {
      // console.log(res);
     this.setState({details:res.data})
     
    })
  }

  HandleEorror=(error)=>{
    console.log(error)
    toast.error('error',{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
      });
      this.setState({loading:false})
  }

  componentDidMount(){
      this.LoadLocations()
  }
  
    render() {
      let classs = true;
      if (window.innerWidth <= 700) {
        classs = true;
      }else{
        classs = false;
      }
      if (localStorage.getItem('userToken')) {
        //return <Redirect to="/dashboard" />
      }
        return (
            <div>
              <Toaster />
            <div className="back-to-home rounded d-none d-sm-block shadow">
        <Link to="/" className="text-white title-dark rounded d-inline-block text-center">
        <i className="fa fa-home"></i>
        </Link>
    </div>
    <section className="cover-user bg-white">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-lg-5 cover-my-30 order-2">
            <div className="cover-user-img d-flex align-items-center" style={{overflow:'scroll'}}>
              <div className="row mt-5" style={{overflow:'scroll'}}>
                <div className="col-12">
                    <div className="empty h-2 desc"></div>
                  <div className="card login-page border-0">
                    <div className="card-body mt-100 p-mo">
                      <h4 className="card-title text-center">Create an account</h4>
                      <form className="login-form mt-4" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>First Name <span className="text-danger">*</span></label>
                                   
                                    <input value={this.state.fistName} onChange={this.handleChange} type="text" className="form-control" placeholder="First Name" name="fistName" autoFocus required />
                                    <small className="text-danger">{this.state.fError}</small>
                                    </div>
                               </div>

                               <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Last Name <span className="text-danger">*</span></label>
                                    
                                    <input value={this.state.lastName} onChange={this.handleChange} type="text" className="form-control" placeholder="Last Name" name="lastName" required />
                                    <small className="text-danger">{this.state.lError}</small>
                                    </div>
                               </div>

                               

                               <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Home Address <span className="text-danger">*</span></label>
                                   
                                    <input value={this.state.address} onChange={this.handleChange} type="address" className="form-control" placeholder="Home address" name="address" required />
                                   
                                    </div>
                               </div>

                               <div className="col-lg-6">
                                <div className="form-group">
                                    <label>State <span className="text-danger">*</span></label>
                                    
                                    <select onChange={this.handleChange} className="form-control" name="state" required>
                                    <option value="">Select State</option>
                                      {this.state.details.length > 0 ?
                                      this.state.details.map((state,i)=>
                                        <option key={state.id} value={i}>{state.name}</option>
                                        )
                                    :
                                    ''
                                    }
                                    </select>
                                    </div>
                               </div>

                               <div className="col-lg-6">
                                <div className="form-group">
                                    <label>City <span className="text-danger">*</span></label>
                                    
                                    <select onChange={this.handleChange} className="form-control" name="city" required>
                                      <option value="">Select City</option>
                                    {this.state.lg.lgas && this.state.lg.lgas.length > 0 ?
                                      this.state.lg.lgas.map((state,i)=>
                                        <option key={state.id} value={state.id}>{state.name}</option>
                                        )
                                    :
                                    ''
                                    }
                                    </select>
                                    </div>
                               </div>

                               <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Country <span className="text-danger">*</span></label>
                                   
                                    <select onChange={this.handleChange} className="form-control" name="country" required>
                                      
                                      <option value="Nigeria">Nigeria</option>
                                    </select>
                                    </div>
                               </div>


                               

                               <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Your Phone Number <span className="text-danger">*</span></label>
                                   
                                    <input value={this.state.number} onChange={this.handleChange} type="number" className="form-control" placeholder="Phone Number" name="number" required="" />
                                    <small className="text-danger">{this.state.numberError}</small>
                                    </div>
                               </div>


                               <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Your Password <span className="text-danger">*</span></label>
                                   
                                    <input value={this.state.password} onChange={this.handleChange} type="password" className="form-control" placeholder="Password" name="password" required="" />
                                    <small className="text-danger">{this.state.passwordError}</small>
                                    </div>
                               </div>

                               <div className="col-lg-12">
                                <div className="form-group">
                                    <label>Confirm Password <span className="text-danger">*</span></label>
                                   
                                    <input value={this.state.password2} onChange={this.handleChange} type="password" className="form-control" placeholder="Confirm Password" name="password2" required="" />
                                    <small className="text-danger">{this.state.passwordError2}</small>
                                    </div>
                               </div>

                                                <div className="col-lg-12 mb-0">
                                                  {this.state.loading ?
                                                  <center>
                                                    <Spinner />
                                                  </center>
                                                :
                                                <button type="submit" className="btn btn-primary btn-block">Register</button>
                                                }
                                                    
                                                </div>
                                
                                {/* <div className="col-lg-12 mt-4 text-center">
                                 <h6>Or Sign Up With</h6>
                                 <ul className="list-unstyled social-icon mb-0 mt-3">
                                   <li className="list-inline-item">
                                     <Link to="#" className="rounded"><i className="fa fa-instagram "></i></Link>
                                   </li>
                                   <li className="list-inline-item">
                                     <Link to="#" className="rounded"><i className="fa fa-instagram "></i></Link>
                                   </li>
                                   <li className="list-inline-item">
                                     <Link to="#" className="rounded"><i className="fa fa-instagram "></i></Link>
                                   </li>
                                   <li className="list-inline-item">
                                     <Link to="#" className="rounded"><i className="fa fa-instagram "></i></Link>
                                   </li>
                                 </ul>
                                </div> */}

                                <div className="col-12 text-center">
                                        <p className="mb-0 mt-3"><small className="text-dark mr-2">Already have an account ?</small> <Link to="/login" className="text-dark font-weight-bold">Sign In</Link></p>
                                    </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 offset-lg-5 padding-less img order-1 short" style={{backgroundImage:'url(https://picsum.photos/2000/1333)',filter:'brightness(0.3)'}}>
          <span>
                  {classs?
                  <div className="back-to-home bg-success rounded d-sm-block shadow">
                  <Link to="/" style={{background:'#ffffff'}} className=" text-dark title-dark rounded d-inline-block text-center">
                  <i className="fa fa-home"></i>
                  </Link>
              </div>
                :''}
                </span>
          </div>
        </div>
      </div>
    </section>
        </div>
        );
    }
}

export default Register;