  
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Home } from '../global/Home';
import axios from 'axios';
import success from '../img/success.png';
import Spinner from './loader/Spinner';
import Axios from 'axios';

class AddressDetailsPop extends Component {
    constructor(props) {
        super(props);
        this.state ={
            style:"",
            fetching:false,
            details:"",
            value: 3,
            comment:"",
            done:"",
            loading:false
        }
    }

    handleClick=(event)=>{
        //console.log(event);
        this.setState({value:event});
      }
    changeStyle=(name)=>{
        this.setState({style:name})
    }
  


    componentDidMount(){
        //this.loadData();
          this.interval = setTimeout(() => this.changeStyle('show'), 5);
          
        }
      
        componentWillUnmount() {
          clearInterval(this.interval);
          this.setState({style:''});
        }
    
    render() {
        
        return (
            
            <div className={'modal fade  '+this.state.style} id="exampleModalCenter"  role="dialog" style={{display:'block',background:'#050404d4',overflow:'scroll',zIndex:'11111'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content card explore-feature border-0 rounded bg-white shadow">
                    <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">  Our Account Address</h5>
                    <button onClick={this.props.close} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
               <div className="modal-body">
                   <div className="p-2">
                   <h4 className="alert alert-primary text-center">{this.props.address}</h4>
                   </div>
               </div>


                    </div>
                </div>
                </div>
           
        );
    }
}

export default AddressDetailsPop;