import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div>
                <div className="position-relative">
                <div className="shape overflow-hidden text-footer">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="https://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
                </div>
                <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a className="logo-footer" href="#">Coinwells<span className="text-primary">.</span></a>
                        <p className="mt-4">
                        Coinwells is a reputable, dedicated and customer-oriented electronic-currency exchange service provider for BitCoins, PerfectMoney and WebMoney. We also provides instant funding in our offices and funding for all bank deposit. With CoinWells, every customer is 'The Customer'.
                        </p>
                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                            <li className="list-inline-item"><a href="#" className="rounded"><svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                            <li className="list-inline-item"><a href="#" className="rounded"><svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a></li>
                            <li className="list-inline-item"><a href="#" className="rounded"><svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                            <li className="list-inline-item"><a href="#" className="rounded"><svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                        </ul>
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Company</h4>
                        <p className="mt-4">
                        Plot 3 Olopo Mewa Str, Oluseyi Bus- Stop, Eleyele, Ibadan, Oyo State,Nigeria
<br></br>
P.: +2348062365982, 08072712054
<br></br>
E.: support@coinwells.com
                        </p>
                        
                    </div>
                    

                    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Newsletter</h4>
                        <p className="mt-4">Daily Currency and Exchange update from Coinwells.</p>
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="foot-subscribe form-group position-relative">
                                        <label>Write your email <span className="text-danger">*</span></label>
                                        <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"  strokeWidth="2" strokeLinecap="round"  strokeLinejoin="round" className="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Your email : " required=""/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary btn-block" value="Subscribe"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </footer>

        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-6">
                        <div className="text-sm-left">
                            <p className="mb-0">© 2020 Coinwells. All right reserved .</p>
                        </div>
                    </div>

                    <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <ul className="list-unstyled text-sm-right mb-0">
                            <li className="list-inline-item"><a href="#"><img src="https://api.coinwells.com/uploads/payments/american-ex.png" className="avatar avatar-ex-sm" title="American Express" alt=""/></a></li>
                            <li className="list-inline-item"><a href="#"><img src="https://api.coinwells.com/uploads/payments/discover.png" className="avatar avatar-ex-sm" title="Discover" alt=""/></a></li>
                            <li className="list-inline-item"><a href="#"><img src="https://api.coinwells.com/uploads/payments/master-card.png" className="avatar avatar-ex-sm" title="Master Card" alt=""/></a></li>
                            <li className="list-inline-item"><a href="#"><img src="https://api.coinwells.com/uploads/payments/paypal.png" className="avatar avatar-ex-sm" title="Paypal" alt=""/></a></li>
                            <li className="list-inline-item"><a href="#"><img src="https://api.coinwells.com/uploads/payments/visa.png" className="avatar avatar-ex-sm" title="Visa" alt=""/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
            </div>
        );
    }
}

export default Footer;